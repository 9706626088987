import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiCall } from "../services/api-call";

@Component({
  selector: "app-payment-page",
  templateUrl: "./payment-page.component.html",
  styleUrls: ["./payment-page.component.scss"],
})
export class PaymentPageComponent implements OnInit {
  spinning: boolean = false;
  waybill: any;
  openPopUp: boolean = false;
  waybillDetails: any;
  succesfulUpload: any;
  dataCode: any;
  constructor(private route: ActivatedRoute, private apiCall: ApiCall) {
    // this.route.params.subscribe(params=>this.waybill = params.waybill) ***added i replaced the below code
    this.route.params.subscribe((params) => (this.waybill = params["waybill"]));
  }

  getWaybillDetails(id: any) {
    this.spinning = true;
    this.apiCall.getWaybillDetails(id).subscribe((data) => {
      this.spinning = false;
      this.dataCode = data.Code;
      if (data.Code == 200) {
        this.waybillDetails = data.Object;
      }
    });
  }
  reload() {
    location.reload();
  }
  processPayment() {
    this.spinning = true;
    this.apiCall.payForShipment(this.waybill).subscribe((data) => {
      this.spinning = false;
      this.openPopUp = true;
      if (data.Code == 200) {
        this.succesfulUpload = data;
      } else {
        this.succesfulUpload = data;
      }
    });
  }

  closePopUp() {
    this.openPopUp = false;
  }
  ngOnInit(): void {
    this.getWaybillDetails(this.waybill);
  }
}
