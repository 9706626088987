import { Injectable } from '@angular/core';
import { Login } from './../models/login';
import { Config } from '../models/utility';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private baseUrl = environment.baseUrl;
  constructor(private httpClient: HttpClient) {}
  public getLogin(loginModel: Login): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/customerLogin`,
      loginModel
    );
  }
}

