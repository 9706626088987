// export class General {
//   public numberWithCommas(num) {
//     return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
//   }
// }

export class General {
  public numberWithCommas(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}
