
<div class="overlays" *ngIf="openPopUp">
    <div class="password-message">
      <div class="message">{{succesfulUpload.ShortDescription}}</div>
      <button *ngIf="succesfulUpload.Code == 200" (click)='reload()' >Ok</button>
      <button *ngIf = "succesfulUpload.Code !=200" (click)='closePopUp()'>close</button>
    </div>
  </div>

<div class="container-class">
    <div class="container-content">

        <div class="payment-container">
            <div class="payment-div" *ngIf="dataCode != 200">
                Waybill does not exist
            </div>
            <div class="payment-div" *ngIf="dataCode == 200 ">
                <div *ngIf="waybillDetails.PaymentStatus =='Paid' ">
                    <div class="payment-container-content">
                        <div class="text-center">Payment succesfully made for </div>
                        <div class="waybill"> {{waybill}}</div>
                    </div>
                </div>
                <div *ngIf="waybillDetails.PaymentStatus !='Paid' ">
                    <div class="payment-container-content margin-bottom">
                        <label>Waybill - </label>
                        <span>{{waybill}}</span>
                    </div>
                    <div class="payment-container-content amount-div"> 
                        <label>Amount</label>
                        <span>{{waybillDetails.Country.CurrencySymbol}} {{waybillDetails.Shipment.GrandTotal | number}}</span>
                    </div>
                </div>
                <div class="button-container" *ngIf="waybillDetails.PaymentStatus !='Paid'">
                    <button class="button-pay" (click)= "processPayment()" >Pay</button>
                </div>
            </div>
           
        </div>
    </div>
</div>