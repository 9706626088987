
<div class="container-class dashboard">
  <div class="container-content dashboard-content">
  
    <div>
      <!-- <app-greeting></app-greeting> -->
     <app-custom-text [text]="'Funds credited to your wallet are non refundable and cannot be withdrawn. They shall be applied solely towards your transactions within GIGL Ecosystem.'"></app-custom-text>
    </div>
    
    <div class=" dashboard-cards">
      <div class="dashboard-cards-item display-card shipment-card">
        <span class="border-line1"></span>
        <div class="display-card-text flex-direction-change">
          <p>Shipment Ordered</p>
          <h2>{{ shipmentOrdered }}</h2>
        </div>
        <div class="flex-container flex-container-1">
          <div class="display-icon-container shipment-card-container">
            <img src="../../assets/images/truck-icon.png" alt="" />
          </div>
        </div>
      </div>
      <div
        class="dashboard-cards-item display-card wallet-card wallet-card-container"
      >
        <span class="border-line2"></span>
        <div class="display-card-text flex-direction-change">
          <p>wallet Balance</p>
          <h2>{{ currencySymbol }}{{ walletBalance | number }}</h2>
        </div>
        <div class="flex-container">
          <div class="display-icon-container wallet-icon-container">
            <img src="../../assets/images/wallet-icon.png" alt="" />
          </div>
        </div>
      </div>


      <div class=" display-card default-width">
        <div class="fund-section">
          <div class="card-section-header">
            <h4>Fund Wallet</h4>
          </div>
          <div class="card-section-form">
            <div class="input-box">
              <input
                type="number"
                (keypress)="keyPress($event)"
                (keyup)="amountChange($event)"
                [(ngModel)]="amount"
                placeholder="Enter Amount"
                pattern="[0-9]"
              />
            </div>

            <div class="btn-box">
              <button (click)="paymentLog()" class="some-fund">
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="printSection"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>Print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Top 5',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>Excel
            
          </button></span
        >
        <!-- <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>PDF
          </button></span >-->
        
      </div>
    </div>
    <div class="dashboard-table display-tbl">
      <div class="dashboard-table-container">
        <div class="dashboard-table-content table-content">
          <div class="table-filter">
            <i class="fas fa-search"></i>
            <input
              class="filter-line"
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
            />
          </div>
          
          <div class="table table-container" id="printSection">
            <table
              mat-table
              matTableExporter
              #exporter="matTableExporter"
              [dataSource]="dataSource"
            >
              <!-- SN Column -->
              <ng-container matColumnDef="sn">
                <mat-header-cell class="custom-header-cell" id="table_header" *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element; let i = index" >
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- waybill column -->
              <ng-container matColumnDef="waybill">
                <mat-header-cell id="table_header"  *matHeaderCellDef
                  >Waybill Number</mat-header-cell
                >
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Waybill">
                  {{ element.Waybill }}
                </mat-cell>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="departure">
                <mat-header-cell id="table_header" *matHeaderCellDef>Departure</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Departure">
                  {{ element.SenderAddress }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="destination">
                <mat-header-cell id="table_header" *matHeaderCellDef>Destination</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Destination">
                  {{ element.ReceiverAddress }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell id="table_header" *matHeaderCellDef>Amount</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Amount">
                  {{ currencySymbol }} {{ element.GrandTotal | number }}
                </mat-cell>
              </ng-container>
              <!-- Receiver -->
              <ng-container matColumnDef="receiver">
                <mat-header-cell id="table_header" *matHeaderCellDef>Receiver</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Receiver">
                  {{ element.ReceiverName }}
                </mat-cell>
              </ng-container>
              <!-- Description -->
              <ng-container matColumnDef="description">
                <mat-header-cell id="table_header" *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Description">
                  {{ element.PreShipmentItems[0].Description }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="date">
                <mat-header-cell id="table_header" *matHeaderCellDef>Date</mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element" data-label="Date">
                  {{ element.DateCreated | date }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="options">
                <mat-header-cell id="table_header" *matHeaderCellDef></mat-header-cell>
                <mat-cell id="table_cell" *matCellDef="let element; let i = index" class="track"
                  >

                  <span id="trackBtn"  class="track-button"  *ngIf="element.Waybill" (click)="trackWaybill(i)">
                    <i
                      class="fas fa-map-marker-alt"
                    ></i>
                    <span class="track-text"
                      >Track</span
                    >

                  </span>
                  
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <!-- <mat-row
                *matRowDef="let row; columns: displayedColumns; "
              ></mat-row> -->
              <mat-row
  *matRowDef="let row; let i = index; columns: displayedColumns;"
  [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
></mat-row>
            </table>

            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="overlay" *ngIf="payment">
  <div class="validation-message">
    <div class="message">Pay With</div>
    <div class="payment-option-container">
      <div class="paystack-container">
        <img
          src="../../assets/images/Paystack.png"
          alt=""
          class="paystack-image"
        />
        <button (click)="paymentLog()">PayStack</button>
      </div>
      <div class="flutterwave-container">
        <img src="../../assets/images/flutterwaveLogo.png" alt="" />
        <button>Flutter<span class="flutter-wave">Wave</span></button>
      </div>
    </div>
  </div>
</div> -->
