export class TransactionHistory {
  StartDate!: string;
  EndDate!: string;
  IsDashboard: boolean = true;
}
export class ShipmentsHistory {
  StartDate!: string;
  EndDate!: string;
  customerCode!: string;
}
