import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCall } from "../services/api-call";
import { Dashboard } from "../models/dashboard";
import { General } from "../helpers/general";
import { AuthHelpers } from "../helpers/auth";
import { DataAccess } from "../helpers/dataAccess";
import { TransactionHistory } from "../models/transactionhistory";
import { Router } from "@angular/router";
declare var PaystackPop: any;
// import * as jsPDF from "jspdf";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  apiError: boolean = false;
  dataSource!: MatTableDataSource<any>;
  dataAccess = new DataAccess();
  public errorMessage!: string;
  public authHelpers = new AuthHelpers();
  spinning: boolean = true;
  amount: any;
  paymentOptions: boolean = false;
  payment: boolean = false;
  public transactionModel: TransactionHistory = new TransactionHistory();
  // responsible for table column
  displayedColumns: string[] = [
    "sn",
    "waybill",
    "departure",
    "destination",
    "amount",
    "receiver",
    "description",
    "date",
    "options",
  ];
  dashboardData: any[] = [];
  walletId: any;
  walletBalance!: number;
  walletOwnerName!: string;
  // walletData;
  walletData: any[] = [];

  public walletNumber: any;
  waybillNumber!: number;
  currencySymbol!: any;
  email = this.authHelpers.getAccessToken("email");
  countryId: any;
  phoneNumber = this.authHelpers.getAccessToken("phoneNumber");
  currencyCode!: string;
  waybillNumberInvoice: any;
  money!: number;
  hide = true;
  shipmentOrdered: any;
  testkey = "pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae";
  testkey_live_Nigeria = "pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b";
  testkey_live_ghana = "pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d";
  public dashboard: Dashboard = new Dashboard();
  public general: General = new General();
  customerName: any;
  customerCode: any;
  refCode: string = "WA-" + Math.floor(Math.random() * 1000000000 + 1) + "-WEB";
  // dashboardDataInfo;
  dashboardDataInfo: any[] = [];

  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  trackWaybill(i: any) {
    this.waybillNumber = this.dashboardData[i].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue("waybillNumber", this.waybillNumber);
     this.router.navigate([`/mainpage/shipmenttracking/${this.waybillNumber}`]);

    // this.apiCall.getTrackShipmentWaybill(this.waybillNumber).subscribe(
    //   {
    //     next: (data) => {
    //       if (data.Code === 200){
    //         this.spinning = true;        
    //       }else{
    //         this.apiError = true;
    //         this.errorMessage = data.ShortDescription;
    //       }  
    //     },
    //     error: (error) => {
    //       this.spinning = false;
    //       this.apiError = true;
    //       this.errorMessage = "error occuredd";
    //       console.error('error tracking shipment with waybill', error)
    //     }
    //   }
    // );
  }

  closePopUp() {
    this.apiError = false;
  }
  fundWallet() {
    this.router.navigate(["/mainpage/walletsummary"]);
  }
  activatePaymentOptions() {
    if (this.amount == null || this.amount == "") {
      return;
    }
    this.payment = true;
  }
  hideButtons() {
    this.hide = false;
  }

  public payWithPaystack() {
    var payStackKey;
    if (this.countryId === 1) {
      payStackKey = this.testkey_live_Nigeria;
    } else if (this.countryId === 76) {
      payStackKey = this.testkey_live_ghana;
    }

    var handler = PaystackPop.setup({
      key: payStackKey,
      email: this.email,
      ref: "" + this.refCode,
      amount: this.amount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response: any) {
        location.reload();
      },
      onClose: function (e: Event) {
        alert("payment cacelled");
      },
      onSuccess: function () {
        location.reload();
      },
    });
    handler.openIframe();
  }

  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId: any) {
    this.waybillNumberInvoice = this.dashboardData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      "waybillNumberInvoice",
      this.waybillNumberInvoice
    );
    this.router.navigate(["/mainpage/printInvoice"]);
  }
  constructor(private apiCall: ApiCall, private router: Router) {}

  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  amountChange(event: KeyboardEvent) {
    if (
      this.amount == null ||
      (event.target as HTMLInputElement).value.length == 0
    ) {
      this.paymentOptions = false;
    }
    return event.charCode >= 48 && event.charCode <= 57;
  }

  loadWallet() {
    this.apiCall.getWallet().subscribe((data) => {
      console.log("DATA GOT HERE", data);
      if (data.Code == 200) {
        this.walletData = data.Object.WalletTransactions;
        this.walletBalance = data.Object.WalletBalance;
        this.walletNumber = data.Object.WalletNumber;
        this.currencySymbol = data.Object.CurrencySymbol;
        this.walletId = data.Object.WalletId;
        this.walletOwnerName = data.Object.WalletOwnerName;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }

  paymentLog() {
    if (this.amount == null || this.amount == "") {
      return;
    }
    this.payment = false;
    this.spinning = true;
    const payload = {
      Amount: this.amount,
      IsWalletCredited: false,
      Reference: this.refCode,
      TransactionStatus: null,
      Wallet: {
        WalletBalance: this.money,
        WalletId: this.walletId,
        WalletNumber: this.walletNumber,
        WalletOwnerName: this.walletOwnerName,
      },
      WalletBalance: this.money,
      WalletId: this.walletId,
      WalletNumber: this.walletNumber,
      WalletOwnerName: this.walletOwnerName,
    };

    this.apiCall.addwalletpaymentlog(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.payWithPaystack();
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  

  ngOnInit(): void {
    this.spinning = true;
    this.apiCall.getDashboard().subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.countryId = data.Object.UserActiveCountry.CountryId;
        this.money = data.Object.WalletBalance;
        // this.phoneNumber = data.Object.UserActiveCountry.ContactNumber
        // localStorage.setItem('phoneNumber',this.phoneNumber)

        this.currencyCode = data.Object.UserActiveCountry.CurrencyCode;
        localStorage.setItem("currencyCode", this.currencyCode);
        this.currencySymbol = data.Object.UserActiveCountry.CurrencySymbol;
        localStorage.setItem("currencySymbol", this.currencySymbol);
        // this.email =  data.Object.UserActiveCountry.ContactEmail
        // localStorage.setItem('email',this.email);
        this.shipmentOrdered = data.Object.TotalShipmentOrdered;
        this.walletBalance = this.money;
        localStorage.setItem("countryId", this.countryId);
        //console.log( this.currencyCode,  this.phoneNumber,this.currencySymbol,  this.email, this.countryId )

        this.loadWallet();
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });

    this.apiCall
      .getTransactionHistory(this.transactionModel)
      .subscribe((data) => {
        if (data.Code == 200) {
          this.spinning = false;
          this.dashboardDataInfo = data.Object.Shipments;
          this.dashboardData = this.dashboardDataInfo;
          this.dashboardData.length = 5;

          for (let i = 0; i <= this.dashboardData.length; i++) {
            this.customerCode = this.dashboardData[0].CustomerCode;
          }
          // stores user code to local storage
          // this.dataAccess.setLocalStorageValue('userCode', this.customerCode);
        } else {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }
        this.dataSource = new MatTableDataSource(this.dashboardData);
      });
    this.apiCall.getCod().subscribe((data) => {
      if (data.Code == 200) {
        const { CashOnDeliveryDetail } = data.Object;
        // gets customer's name
        this.customerName = CashOnDeliveryDetail.Wallet.CustomerName;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
}
