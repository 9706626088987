import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCall } from "../services/api-call";
import { TransactionHistory } from "../models/transactionhistory";
import { MatPaginator } from "@angular/material/paginator";
import { DataAccess } from "../helpers/dataAccess";
import { Router } from "@angular/router";
export interface Information {
  position: number;
  departure: string;
  destination: string;
  status: string;
  amount: string;
  partners: string;
  date: any;
  items: string;
}
@Component({
  selector: "app-transaction-history",
  templateUrl: "./transaction-history.component.html",
  styleUrls: ["./transaction-history.component.scss"],
})
export class TransactionHistoryComponent implements OnInit {
  apiError = false;
  public errorMessage!: string;

  dataSource!: MatTableDataSource<any>;
  public transactionModel: TransactionHistory = new TransactionHistory();
  transactionData: any;
  dataAccess = new DataAccess();
  spinning: boolean = true;
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = [
    "position",
    "waybill",
    "departure",
    "destination",
    "description",
    "amount",
    "receivername",
    "receiverphonenumber",
    "date",
    "button",
  ];
  currencySymbol: any;
  waybillNumber: any;
  waybillNumberInvoice: any;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  closePopUp() {
    this.apiError = false;
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId: any) {
    this.waybillNumberInvoice = this.transactionData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      "waybillNumberInvoice",
      this.waybillNumberInvoice
    );
    this.router.navigate(["/mainpage/printInvoice"]);
  }
  trackWaybill(i: any) {
    this.waybillNumber = this.transactionData[i].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue("waybillNumber", this.waybillNumber);
    // this.router.navigate(["/mainpage/shipmenttracking"]);
    this.router.navigate([`/mainpage/shipmenttracking/${this.waybillNumber}`]);

  }
  getTransactionByDate() {
    this.transactionModel.IsDashboard = false;
    this.getTransaction();
  }
  getTransaction() {
    this.spinning = true;
    this.apiCall
      .getTransactionHistory(this.transactionModel)
      .subscribe((data) => {
        if ((data.Code = 200)) {
          this.spinning = false;
          this.transactionData = data.Object.Shipments;
          this.currencySymbol = data.Object.CurrencySymbol;
        } else {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }
        this.dataSource = new MatTableDataSource(this.transactionData);
        this.dataSource.paginator = this.paginator;
      });
  }

  constructor(private apiCall: ApiCall, private router: Router) {}

  ngOnInit(): void {
    this.getTransaction();
  }
}
