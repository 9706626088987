<div class="container-class tracking">
  <div class="container-content">
    <div>
      <div class="max-w-3xl p-6 mx-auto bg-white rounded-md shadow-md">
       
        <div class="p-3 mt-4 border rounded-md bg-gray-50">
          
          <div
            *ngIf="ShipmentforPodDetails; else noData"
            class="p-4 mt-4 rounded-md"
          >
          <p> <strong>Waybill Number:</strong>
            {{ ShipmentforPodDetails.Waybill }}
          </p>
            <p>
              <strong>Date Delivered:</strong>
              {{ ShipmentforPodDetails.DateDelivered | date : "medium" }}
            </p>
            <p>
              <strong>Delivery Address:</strong>
              {{ ShipmentforPodDetails.DeliveryAddress }}
            </p>
            <p>
              <strong>Phone Number:</strong>
              {{ ShipmentforPodDetails.PhoneNumber }}
            </p>
            <p>
              <strong>Recipient Name:</strong>
              {{ ShipmentforPodDetails.RecipentName }}
            </p>
  
            <!-- Signature Image -->
            <div *ngIf="ShipmentforPodDetails.SignatureUrl">
              <p><strong>Signature:</strong></p>
              <img [src]="ShipmentforPodDetails.SignatureUrl" alt="Signature" />
            </div>
  
            <!-- Parcel Image -->
            <div *ngIf="ShipmentforPodDetails.ParcelUrl">
              <p><strong>Parcel Image:</strong></p>
              <img [src]="ShipmentforPodDetails.ParcelUrl" alt="Parcel" />
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--  when no data is available -->
    <ng-template #noData>
      <p>Your Proof of Delivery Data Will Display Here</p>
    </ng-template>
  </div>
</div>
