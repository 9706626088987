import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ApiCall } from "../services/api-call";
import { ShipmentforPodDetails } from "../models/pod.model";

@Component({
  selector: "app-proofofdelivery",
  templateUrl: "./proofofdelivery.component.html",
  styleUrls: ["./proofofdelivery.component.scss"],
})
export class ProofofdeliveryComponent {
  waybillNumber: string = "";
  shipmentStatus: string = "";
  shipmentDelivered: boolean = false;
  selectedFile: File | null = null;
  signatureData: string | null = null;
  ShipmentforPodDetails: ShipmentforPodDetails | null = null;

  constructor(
    private apiCall: ApiCall,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Get waybill number from URL
    this.route.paramMap.subscribe((params) => {
      this.waybillNumber = params.get("waybillNumber") || "";

      console.log(
        "Received Waybill Number in Proof of Delivery:",
        this.waybillNumber
      );

      if (this.waybillNumber) {
        this.fetchShipmentDetails();
      } else {
        this.toastr.error("Invalid Waybill Number");
      }
    });
  }

  // Fetch Shipment Status
  fetchShipmentDetails() {
    this.apiCall
      .proofOfDelivery(this.waybillNumber)
      .subscribe(
        (response: any) => {
          this.ShipmentforPodDetails = response.Object;
        },
        (error: any) => {
          this.toastr.error("Error fetching shipment details.");
          console.error(error);
        }
      );
  }
}
