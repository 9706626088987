import { Injectable } from "@angular/core";
import { AuthHelpers } from "../helpers/auth";
import { Observable, from, map } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Dashboard } from "../models/dashboard";
import { TransactionHistory } from "../models/transactionhistory";
import { ChangePassword } from "../models/changePassword";
import { ForgotPassword } from "../models/forgotPassword";
import { QuickQuotes } from "../models/quickquote";
import { environment } from "src/environments/environment";
import { ShipmentApiResponse, Shipments } from "../models/shipments";
import { TrackingResponse } from "../models/tracking.model";
import { ProofOfDeliveryResponse } from "../models/pod.model";

@Injectable({
  providedIn: "root",
})
export class ApiCall {
  private baseUrl = environment.baseUrl;
  // private apiUrl = environment.apiUrl;

  public authHelpers = new AuthHelpers();
  SERVER_URL: string =
    "https://giglgodev.herokuapp.com/api/s3upload/image-upload";
  token = this.authHelpers.getAccessToken("accessToken");

  constructor(private httpClient: HttpClient) {}

  authHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");
    headers = headers.append("Authorization", `Bearer ${this.token}`);
    return { headers };
  }

  //   calls the api for dashboard
  getDashboard(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/dashboard`,
      this.authHeader()
    );
  }

  getTransaction(dashboard: Dashboard): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/transaction`,
      dashboard,
      this.authHeader()
    );
  }

  getWaybillDetails(id: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/bywaybill/${id}`,
      this.authHeader()
    );
  }
  getInternationalStores(id: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/storesbycountry/${id}`,
      this.authHeader()
    );
  }
  getInternationalShipmentTerms(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/intlshipmentsmessage`,
      this.authHeader()
    );
  }
  updateInternationalProfile(value: any): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl}/portal/profileinternationaluser`,
      value,
      this.authHeader()
    );
  }

  payForShipment(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/paymentforshipment/${waybill}`,
      this.authHeader()
    );
  }

  getActiveCountries(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/getactivecountries`,
      this.authHeader()
    );
  }

  getStationByCountry(id: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/getstationsbycountry/${id}`,
      this.authHeader()
    );
  }

  getServiceCenterByStation(id: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/servicecentresbystation/${id}`,
      this.authHeader()
    );
  }

  getServiceCenterByCountry(id: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/servicecentresbycountry/${id}`,
      this.authHeader()
    );
  }
  getTransactionHistory2(transaction: TransactionHistory): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/transaction`,
      transaction,
      this.authHeader()
    );
  }
  getTransactionHistory(transaction: TransactionHistory): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/getwallettransactionandpreshipmenthistory`,
      transaction,
      this.authHeader()
    );
  }
  getForgotPassword(forgotPassword: ForgotPassword): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/forgotpassword`,
      forgotPassword,
      this.authHeader()
    );
  }
  // getQuickQuotes(quickquote): Observable<any> {
  getQuickQuotes(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/getPrice`,
      payload,
      this.authHeader()
    );
  }

  identificationTypes(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/identificationtypes`,
      this.authHeader()
    );
  }

  //i replaced this payload this **** getInternationalShipment(payload): Observable<any> {
  getInternationalShipment(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/AddIntlShipmentTransactions`,
      payload,
      this.authHeader()
    );
  }
  getIdentificationTypes(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/identificationtypes`,
      this.authHeader()
    );
  }
  getChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl}/portal/changepassword/${changePassword.userid}/${changePassword.currentPassword}/${changePassword.newPassword}`,
      changePassword,
      this.authHeader()
    );
  }

  getPaymentLog(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/walletpaymentlog`,
      this.authHeader()
    );
  }

  getWallet(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/wallet`,
      this.authHeader()
    );
  }

  getCod(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl}/portal/cod`, this.authHeader());
  }

  getCodRemmitance(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/GetPaidCODByCustomer`,
      this.authHeader()
    );
  }

  getPickupRequest(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/getpreshipmenthistory`,
      this.authHeader()
    );
  }

  getShipmentTracking(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/${waybill}`,
      this.authHeader()
    );
  }

  getInvoice(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/invoice`,
      this.authHeader()
    );
  }

  getVerifyPayment(referenceCode: number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/verifypayment/${referenceCode}`,
      this.authHeader()
    );
  }

  getByWaybill(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/bywaybill/${waybill}`,
      this.authHeader()
    );
  }

  getPickupRequestDetails(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/getpreshipmentmobiledetails/${waybill}`,
      this.authHeader()
    );
  }
  // customer Profile
  getUserProfile(userid: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/user/${userid}`,
      this.authHeader()
    );
  }

  //get banks
  gatewayCode(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/gatewaycode`,
      this.authHeader()
    );
  }

  initiateUSSD(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/initiatepaymentusingussd`,
      payload,
      this.authHeader()
    );
  }

  addwalletpaymentlog(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.baseUrl}/portal/addwalletpaymentlog`,
      payload,
      this.authHeader()
    );
  }
  getimageUpload(formData: FormData): Observable<any> {
    return this.httpClient.post<any>(
      this.SERVER_URL,
      formData,
      this.authHeader()
    );
  }
  getServiceCenter(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/getStations`,
      this.authHeader()
    );
  }
  getDeliveryOptions(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/deliveryoption`,
      this.authHeader()
    );
  }

  getProcessTerminalShipment(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/outstandingpayments`,
      this.authHeader()
    );
  }
  // getHaulage(): Observable<any> {
  //   return this.httpClient.get(
  //     `${this.baseUrl}/portal/haulage`,
  //     this.authHeader()
  //   );
  // }
  // getVat(): Observable<any> {
  //   return this.httpClient.get(`${this.baseUrl}/portal/vat`, this.authHeader());
  // }
  // getInsurance(): Observable<any> {
  //   return this.httpClient.get(
  //     `${this.baseUrl}/portal/insurance`,
  //     this.authHeader()
  //   );
  // }

  getTrackShipmentWaybill(waybill: any): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/portal/trackshipment/${waybill}`,
      this.authHeader()
    );
  }

  

  // getShipments(
  //   StartDate: string,
  //   EndDate: string,
  //   customerCode: string
  // ): Observable<ShipmentApiResponse> {
  //   const payload = {
  //     StartDate,
  //     EndDate,
  //     customerCode,
  //   };
  //   return this.httpClient.post<ShipmentApiResponse>(
  //     `${this.baseUrl}/shipment/vfsshipments/nopaginated`,
  //     payload,
  //     this.authHeader()
  //   );
  // }

  getAllShipments(
    CurrentPage: number,
    PageSize: number,
    StartDate: Date | string,
    EndDate: Date | string,
  ) {
    const payload = {
      CurrentPage,
      PageSize,
      StartDate,
      EndDate,
    };

    return this.httpClient.post<any>(
      `${this.baseUrl}/shipment/vfsshipments`,
      payload,
      this.authHeader()
    );
  }
// BY REFERENCE NUMBER
  trackByReferenceNumber(referenceNumber: string): Observable<TrackingResponse> {
    return this.httpClient.get<TrackingResponse>(
      `${this.baseUrl}/shipmenttrack/refno/${referenceNumber}`,
      this.authHeader()

    );
  }

  // BY PROOF OF DELIVERY
  proofOfDelivery(waybillNumber: string): Observable<ProofOfDeliveryResponse>{
    return this.httpClient.get<ProofOfDeliveryResponse>(
      `${this.baseUrl}/shipment/proofofdelivery/${waybillNumber}`,
      this.authHeader()
    );
  }
}
