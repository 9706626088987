import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCall } from "../services/api-call";
import { MatPaginator } from "@angular/material/paginator";
import { DataAccess } from "../helpers/dataAccess";
export interface Information {
  position: number;
  departure: string;
  destination: string;
  status: string;
  amount: string;
  partners: string;
  date: any;
  items: string;
}
@Component({
  selector: "app-cod",
  templateUrl: "./cod.component.html",
  styleUrls: ["./cod.component.scss"],
})
export class CodComponent implements OnInit {
  apiError: boolean = false;
  public errorMessage!: string;
  dataSource!: MatTableDataSource<any>;
  public walletNumber: any;
  public customerName: any;
  public tableData: any;
  // spinning: boolean = true;
  dataAccess = new DataAccess();
  // responsible for table column
  displayedColumns: string[] = [
    "position",
    "date",
    "amount",
    "credit",
    "status",
    "description",
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(private apiCall: ApiCall) {}
  // closes pop up
  closePopUp() {
    this.apiError = false;
  }
  //get cod information
  getCod() {
    // this.spinning = true;
    this.apiCall.getCod().subscribe((data) => {
      if (data.Code == 200) {
        // this.spinning = false;
        //destructure the response object
        const { CashOnDeliveryAccount, CashOnDeliveryDetail } = data.Object;

        //wallet number
        this.walletNumber = CashOnDeliveryDetail.Wallet.WalletNumber;

        //customer name
        this.customerName = CashOnDeliveryDetail.Wallet.CustomerName;

        //data source
        this.dataSource = new MatTableDataSource(CashOnDeliveryAccount);
      } else {
        // this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  ngOnInit(): void {
    this.getCod();
  }
}
