import { FormGroup, AbstractControl } from "@angular/forms";

// handles cross evaluation
export function ConfirmedValidator(control: AbstractControl) {
  const password = control.get("password");
  const confirmPassword = control.get("confirm_password");
  // if (password.pristine || confirmPassword.pristine) {
  //   return null;
  // }

  //ADDED
  // Check if password or confirmPassword is null
  if (!password || !confirmPassword) {
    return null; // Return null if either control is null
  }

  if (password.pristine || confirmPassword.pristine) {
    return null;
  }

  // returns misMatch if password and confirm password does not match
  return password && confirmPassword && password.value != confirmPassword.value
    ? { misMatch: true }
    : null;
}
