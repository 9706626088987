import { Component, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { DataAccess } from "../helpers/dataAccess";
import { ApiCall } from "../services/api-call";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-track",
  templateUrl: "./track.component.html",
  styleUrls: ["./track.component.scss"],
})
export class TrackComponent {
  referenceNumber: string = "";
  referenceTrackingData: any[] = [];
  showReferenceTrackDisplay: boolean = false;
  showWaybillTrackDisplay: boolean = false;
  selectedCard: string | null = null;

  // track by reference codebase

  apiError: boolean = false;
  public errorMessage!: string;
  trackingData: any[] = [];
  tracking: boolean = false;
  dataAccess = new DataAccess();
  spinning: boolean = false;
  waybill!: string;
  waybills!: string;
  waybillss!: string;
  origin!: string;
  destination!: string;
  hasProofOfDelivery: boolean = false;
  status!: string;
  myWaybill!: number;
  dataSource = new MatTableDataSource<any>();
  showTrackDisplay: boolean = false;
  waybillNumberInvoice: any;

  shipmentTrackings: any[] = [];
  waybillwidth = this.dataAccess.getLocalStorageValue("waybillNumber") || null;
  // responsible for table column
  displayedColumns: string[] = [
    "position",
    "waybill",
    "date",
    "time",
    "location",
    "status",
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    private apiCall: ApiCall,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe((params) => (this.waybill = params["waybill"]));
  }
  closePopUp() {
    this.apiError = false;
  }
  // trackShipment() {
  //   this.spinning = true;
  //   this.apiCall.getShipmentTracking(this.waybill).subscribe((data) => {
  //     this.spinning = true;
  //     if (data.Code == 200) {
  //       this.spinning = false;
  //       this.tracking = true;
  //       this.trackingData = data.Object;
  //     } else {
  //       this.spinning = false;
  //       this.apiError = true;
  //       this.errorMessage = data.ShortDescription;
  //     }
  //     this.dataSource = new MatTableDataSource(this.trackingData);
  //   });
  // }

  // trackShipmentWith(waybill: number) {
  //   this.spinning = true;
  //   this.apiCall.getShipmentTracking(waybill).subscribe((data) => {
  //     this.spinning = true;
  //     if ((data.Code === 200)) {
  //       this.spinning = false;
  //       this.tracking = true;
  //       this.trackingData = data.Object;
  //     } else {
  //       this.spinning = false;
  //       this.apiError = true;
  //       this.errorMessage = data.ShortDescription;
  //     }
  //     this.dataSource = new MatTableDataSource(this.trackingData);
  //   });
  // }

  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId: any) {
    this.waybillNumberInvoice = this.trackingData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      "waybillNumberInvoice",
      this.waybillNumberInvoice
    );
    this.router.navigate(["/mainpage/printInvoice"]);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const waybillParam = params.get("waybillNumber");
      if (waybillParam) {
        this.waybill = waybillParam;
        this.getToTrackWaybill(); // Auto-fetch if waybill is provided in route
      }
    });
  }

  // ***
  getToTrackWaybill(): void {
    if (!this.waybill) {
      this.apiError = true;
      this.errorMessage = "Please enter a waybill number";
      return;
    }
    this.spinning = true;

    this.apiCall.getTrackShipmentWaybill(this.waybill).subscribe(
      (data: any) => {
        this.spinning = false;
        if (data.Code === "200") {
          this.showTrackDisplay = true;
          const trackingData = data.Object;
          this.origin = trackingData?.Origin;
          this.destination = trackingData?.Destination;
          this.hasProofOfDelivery = trackingData?.HasProofOfDelivery || false; 
          this.shipmentTrackings = trackingData?.MobileShipmentTrackings || [];

          for (let i = 0; i < this.shipmentTrackings.length; i++) {
            this.myWaybill = this.shipmentTrackings[i]?.Waybill;
            this.status = this.shipmentTrackings[i]?.ScanStatus.Reason;
          }
          this.dataSource.data = this.shipmentTrackings.map((tracking: any) => {
            return {
              Waybill: tracking.Waybill,
              DateTime: tracking.DateTime,
              Location: tracking.Location,
              User: tracking.User,
              Status: tracking?.ScanStatus?.Reason ?? "No status available",
            };
          });
        } else {
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }
      },
      (error) => {
        this.spinning = false;
        this.apiError = false;
        this.errorMessage = "An error occurred while tracking the shipment";
        console.error("Error tracking shipment:", error);
      }
    );
  }


  // selectCard(cardType: string): void {
  //   this.selectedCard = cardType;
  // }

  // track by reference code
  // trackByReferenceNumber() {
  //   if (!this.referenceNumber) {
  //     this.toastr.error("Please enter a reference number");
  //     return;
  //   }

  //   this.apiCall.trackByReferenceNumber(this.referenceNumber).subscribe(
  //     (response) => {
  //       if (response?.Object?.length) {
  //         this.referenceTrackingData = response.Object.map((item: any) => ({
  //           shipmentTrackingid: item.ShipmentTrackingId,
  //           location: item.Location,
  //           date: item.DateTime,
  //           waybilll: item.Waybill,
  //           reason: item.ScanStatus?.Reason || "No reason provided",
  //         }));
  //         this.showReferenceTrackDisplay = true;
  //         console.log("RESPONSE HERE", response);
  //         this.toastr.success(" Information retrieved successfully!");
  //       } else {
  //         this.toastr.warning("No tracking data found.");
  //         this.showReferenceTrackDisplay = false;
  //       }
  //     },
  //     (error) => {
  //       console.error("Error fetching tracking data:", error);
  //       this.toastr.error(
  //         "Failed to retrieve tracking information. Please try again."
  //       );
  //     }
  //   );
  // }
}
