
<div class="container-class">
  <div class="container-content">
    <div class="page-text">
      <h2>Pick-up Request Detail</h2>
    </div>
    <div class="request-detail">
      <div class="request-detail-one">
        <div class="pickup-table">
          <table>
            <tr>
              <td>Waybill</td>
              <td>{{ waybillNumber }}</td>
            </tr>
            <tr>
              <td>Departure Service Centre</td>
              <td></td>
            </tr>
            <tr>
              <td>Destination Service Centre</td>
              <td></td>
            </tr>
            <tr>
              <td>Delivery Option</td>
              <td></td>
            </tr>
            <tr>
              <td>Pickup Options</td>
              <td></td>
            </tr>
            <tr>
              <td>Total</td>
              <td>₦{{ total | number }}</td>
            </tr>
            <tr>
              <td>Grand Total</td>
              <td>₦{{ grandTotal | number }}</td>
            </tr>
            <tr>
              <td>Value</td>
              <td>₦{{ value }}</td>
            </tr>
            <tr *ngIf="showAmount">
              <td>Cash On Delivery</td>
              <td>₦{{ cashOnDelivery | number }}</td>
            </tr>
            <tr *ngIf="showAmount">
              <td>Value Of Shipment</td>
              <td></td>
            </tr>
            <tr>
              <td>VAT</td>
              <td>₦{{ vat | number }}</td>
            </tr>
            <tr>
              <td>Discount Value</td>
              <td>₦{{ discount | number }}</td>
            </tr>
            <tr>
              <td>Invoice Discount Value</td>
              <td>₦{{ invoiceDiscount | number }}</td>
            </tr>
            <tr>
              <td>Off Invoice Discount Value</td>
              <td>₦{{ offInvoice | number }}</td>
            </tr>
            <tr>
              <td>Shipment Package Price</td>
              <td>₦{{ shipmentPackagePrice }}</td>
            </tr>
            <tr>
              <td>Date Created</td>
              <td>{{ date | date }}</td>
            </tr>
          </table>
        </div>
        <div class="shipment-items">
          <div class="table">
            <table mat-table [dataSource]="dataSource">
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- items column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>DESCRIPTION</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.Description }}</mat-cell
                >
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="nature">
                <mat-header-cell *matHeaderCellDef>NATURE</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.ShipmentType }}</mat-cell
                >
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="weight">
                <mat-header-cell *matHeaderCellDef
                  >WEIGHT/VOLUME (HEIGHT * LENGHT * WIDTH)(CM)</mat-header-cell
                >
                <mat-cell *matCellDef="let element"
                  >{{ element.Weight }}kg
                </mat-cell>
              </ng-container>
              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <!-- <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row> -->
              <mat-row
  *matRowDef="let row; let i = index; columns: displayedColumns;"
  [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
></mat-row>
            </table>
          </div>
        </div>
      </div>
      <div class="request-detail-two">
        <div class="customer-information">
          <div class="customer-information-header">
            <span>Customer Information</span>
          </div>
          <div class="customer-information-table">
            <table>
              <tr>
                <td>Name</td>
                <td>{{ customerName }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td></td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{{ customerNumber }}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{{ customerAddress }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="receiver-information">
          <div class="receiver-information-header">
            <span>Receiver Information</span>
          </div>
          <div class="customer-information-table">
            <table>
              <tr>
                <td>Name</td>
                <td>{{ receiverName }}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{{ receiverEmail }}</td>
              </tr>
              <tr>
                <td>Phone Number</td>
                <td>{{ receiverNumber }}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{{ receiverAddress }}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{{ receiverState }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="shipment-description">
          <div class="shipment-description-header">
            <span>Shipment Description</span>
          </div>
          <div class="shipment-description-table"></div>
        </div>
      </div>
    </div>
  </div>
</div>
