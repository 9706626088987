
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMsg}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="overlay" *ngIf="errors">
  <div class="error-message">
    <div class="message">{{ errorMessage }}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="container-class quickquote">
  <div class="container-class quickquote-content">
    <!-- <app-greeting></app-greeting> -->
   
    <!-- <div class="page-text">
      <h2 style="font-size: 13px;">Quick Quote</h2>
    </div> -->
    <div *ngIf="Total" class="grandTotal">
      Grand Total: ₦ {{ grandTotal | number }}
    </div>
    <div class="display-card quickquote-container">
      <div class="quickquote-content">
        
        <div class="shipment-option-section">
          <div class="quote-section quote-section-1">
            <div class="section-header">Shipment Options</div>
            <div class="form-section">
              <div class="select">
                <label>Departure</label>
                <select
                  [(ngModel)]="senderStationId"
                  (change)="onOptionsSelected($event)"
                  name=""
                  id=""
                >
                  <option value="">Pick a Departure Service Center</option>
                  <option
                    [ngValue]="data.StationId"
                    *ngFor="let data of data3"
                  >
                    {{ data.Name }}{{ data.StationName }}
                  </option>
                </select>
              </div>
              <div class="select">
                <label>Destination</label>
                <select
                  name=""
                  id=""
                  [(ngModel)]="receiverStationId"
                  (change)="onOptionsSelected($event)"
                >
                  <option value="">Pick a Destination Service Center</option>
                  <option
                    [ngValue]="data.StationId"
                    *ngFor="let data of data3"
                  >
                    {{ data.Name }}{{ data.StationName }}
                  </option>
                </select>
              </div>
              <!-- <div class="select">
                <label>Delivery Options (Multi)</label>
                <select name="" id="">
                  <option value="">Select Delivery Option</option>
                  <option
                    value="data.DeliveryOptionId"
                    *ngFor="let data of deliveryOptionData"
                  >
                    {{ data.Description }}
                  </option>
                </select>
              </div> -->
              <!-- <div class="select">
                <label>Pick Up Options </label>
                <select name="" id="">
                  <option value=""></option>
                </select>
              </div> -->
            </div>
          </div>

          <div class="quote-section quote-section-2">
            <div class="section-header">Shipment Items</div>

            <div class="shipment-item-display">
              <div class="regular-shipment">
                <form
                  action=""
                  [formGroup]="quickQuoteForm"
                  (submit)="CallQuickQuotes()"
                >
                  <input
                    formControlName="weight"
                    type="number"
                    placeholder="Weight (Kg)"
                    [(ngModel)]="weight"
                  />

                  <input
                    formControlName="value"
                    type="number"
                    placeholder="Value (₦)"
                    [(ngModel)]="value"
                  />
                  <input
                    formControlName="quantity"
                    type="number"
                    placeholder="Quantity(1)"
                    [(ngModel)]="quantity"
                  />
                  <button
                    class="submit-quick-quotes"
                    [disabled]="!quickQuoteForm.valid"
                  >
                    Get a Quote
                  </button>
                </form>
              </div>
              <!-- <div
                class="haulage-shipment"
                *ngFor="let value of data2; let i = index"
              >
                <select name="specialHaulage" id="special-haulage">
                  <option value="">special haulage</option>
                  <option
                    value="data.HaulageId"
                    *ngFor="let data of haulageData"
                  >
                    {{ data.Tonne }} ==> tonnes
                  </option>
                </select>
                <input value="{{ value.number }}" />
                <span class="close" (click)="removeItem2(i)">x</span>
              </div> -->
              <!-- <div class="action-btn">
                <button class="add-btn button-submit" (click)="addData()">
                  Add Regular
                </button>
                <button class="add-btn button-submit" (click)="addData2()">
                  Add Haulage
                </button>
              </div> -->
            </div>
          </div>

          <!-- <div class="quote-section quote-section-3">
            <div class="section-header">Shipment Category</div>
            <div class="section-content-data">
              <span>Are Items Delared?</span><input type="checkbox" /><input
                class="input-text"
                placeholder="0"
              />
            </div>
          </div> -->
          <!-- <div class="quote-section quote-section-4">
            <div class="section-header">Discount and Charges</div>
            <div class="section-content section-charges">
              <div class="section-content-detail">
                <span>Vat %:</span><input placeholder="" value="{{ vat }}" />
              </div>
              <div class="section-content-detail">
                <span>Insurance %:</span
                ><input placeholder="" value="{{ insurance }}" />
              </div>
              <div class="section-content-detail">
                <span>On-Invoice %:</span><input placeholder="" />
              </div>
            </div>
          </div> -->

          <!-- <div class="price-summary">
            <div class="price-summary-container">
              <table>
                <tr>
                  <td>VAT</td>
                  <td class="money">₦</td>
                </tr>
                <tr>
                  <td>Insurance</td>
                  <td class="money">₦</td>
                </tr>
                <tr>
                  <td>On Invoice Discount</td>
                  <td class="money">₦</td>
                </tr>
                <tr>
                  <td>Off Invoice Discount</td>
                  <td class="money">₦</td>
                </tr>
                <tr>
                  <td>Total</td>
                  <td class="money">₦</td>
                </tr>
                <tr>
                  <td class="grand-total">Grand Total</td>
                  <td class="money">₦</td>
                </tr>
              </table>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
