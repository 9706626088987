import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CodComponent } from './cod/cod.component';
import { CodremittancesComponent } from './codremittances/codremittances.component';
import { CreateshipmentComponent } from './createshipment/createshipment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { NavSideBarComponent } from './nav-side-bar/nav-side-bar.component';
import { PaymentLogComponent } from './payment-log/payment-log.component';
import { PickUpReequestComponent } from './pick-up-reequest/pick-up-reequest.component';
import { QuickQuoteComponent } from './quick-quote/quick-quote.component';
import { SlaComponent } from './sla/sla.component';
import {PaymentPageComponent} from './payment-page/payment-page.component'
import { TrackingComponent } from './tracking/tracking.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { WalletComponent } from './wallet/wallet.component';
import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { PickUpRequestDetailComponent } from './pick-up-request-detail/pick-up-request-detail.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuard } from './services/auth.guard';
import {PublictrackComponent} from './publictrack/publictrack.component';
import { ShipmentTransactionComponent } from './shipment-transaction/shipment-transaction.component';
import { ProcessTerminalShipmentComponent } from './process-terminal-shipment/process-terminal-shipment.component';

import { from } from 'rxjs';
import { TrackbtnComponent } from './trackbtn/trackbtn.component';
import { TrackComponent } from './track/track.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { ProofofdeliveryComponent } from './proofofdelivery/proofofdelivery.component';
// import { TrackComponent } from './track/track.component';


const routes: Routes = [
  {
    path: 'mainpage',
    component: NavSideBarComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'cashondelivery', component: CodComponent },
      { path: 'codreceipthistory', component: CodremittancesComponent },
      { path: 'createshipment', component: CreateshipmentComponent },
      { path: 'invoice', component: InvoiceComponent },
      { path: 'paymentlog', component: PaymentLogComponent },
      { path: 'pickuprequest', component: PickUpReequestComponent },
      { path: 'pickuprequestdetail', component: PickUpRequestDetailComponent },
      { path: 'quickquote', component: QuickQuoteComponent },
      { path: 'paymentpage/:waybill',component:PaymentPageComponent},
      { path: 'sla', component: SlaComponent },
      { path: 'shipmenttracking/:waybillNumber', component: TrackingComponent }, 
      { path: 'shipmenttracking', component: TrackComponent},
      { path: 'customertransactions', component: TransactionHistoryComponent },
      { path: 'walletsummary', component: WalletComponent },
      { path: 'changePassword', component: PasswordChangeComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'printInvoice', component: ViewInvoiceComponent },
      { path: 'shipmenttransaction', component: ShipmentTransactionComponent },
      { path: 'proofofdelivery/:waybillNumber', component: ProofofdeliveryComponent},
      { path: 'processterminalshipment', component: ProcessTerminalShipmentComponent},
      { path: 'allshipment', component: ShipmentComponent},
      { path: '', component: DashboardComponent },

    ],
  },
   {path:'public/track/:waybill',component:PublictrackComponent},
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
