<div class="container-class tracking">
  <div class="container-content">
    <!-- <app-greeting></app-greeting> -->
    <div class="page-text">
    
      <a
        [href]="'https://giglogistics.com/track/' + waybill"
        target="_blank"
        rel="noopener noreferrer"
        class="cursor-pointer"
      >
        Track via the GIG Logistics website with your waybill number
      </a>
    </div>

    <div class="tracking-information">
      <!-- <div class="cursor-pointer tracking-info-section display-card" 
       [class.active]="selectedCard === 'reference'" 
      [class.inactive]="selectedCard && selectedCard !== 'reference'" 
      (click)="selectCard('reference')"
      > -->
      <!-- track by waybill no -->
      <div class="cursor-pointer tracking-info-section display-card">
        <h4 class="tracking-intro-text">Track by Waybill Number</h4>
        <div class="tracking-input">
          <div class="input-container">
            <input
              type="text"
              [(ngModel)]="waybill"
              placeholder="Enter Waybill Number"
            />
          </div>
          <div class="button-container">
            <button
              class="track-button"
              (click)="getToTrackWaybill()"
              [disabled]="!waybill"
            >
              Track by Waybill Number
            </button>
          </div>
        </div>
      </div>
      <!-- track by reference -->
      <!-- <div class="tracking-info-section display-card" [class.active]="selectedCard === 'waybill'" 
      [class.inactive]="selectedCard && selectedCard !== 'waybill'" 
      (click)="selectCard('waybill')">
        <h4 class="tracking-intro-text">Track by Reference Number</h4>
          <div class="tracking-input">
            <div class="input-container">
              <input
                type="text"
                placeholder="Enter Your Reference Number"
                [(ngModel)]="referenceNumber"
              />
            </div>
            <div class="button-container">
              <button (click)="trackByReferenceNumber()"
                class="track-button"
              >
                Track by Reference Number
              </button>
            </div>
          </div>
      </div> -->
    </div>
    <div *ngIf="showReferenceTrackDisplay" class="trackDisplay">
      <h2 class="tracking-caption">Reference Number Tracking Information</h2>
      <table
        mat-table
        [dataSource]="referenceTrackingData"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="waybill">
          <mat-header-cell *matHeaderCellDef> Waybill </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.waybilll }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.location }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.date | date
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.reason }}</mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="['status', 'location', 'waybill', 'date']"
        ></mat-header-row>
        <mat-row
          *matRowDef="
            let row;
            columns: ['status', 'location', 'waybill', 'date']
          "
        ></mat-row>
      </table>
    </div>

    <div class="trackDisplay" *ngIf="showTrackDisplay">
      <h2 class="tracking-caption">Tracking information</h2>
      <div class="track-information">
        <div class="tracking-details">
          <div>
            <h3>Waybill Number</h3>
            <p>{{ myWaybill }}</p>
          </div>
          <div>
            <h3>Departure Address</h3>
            <p>{{ origin }}</p>
          </div>
          <div>
            <h3>Destination Address</h3>
            <p>{{ destination }}</p>
          </div>
        </div>
      </div>

      <!-- the display -->
      <div class="tracking-table display-tbl">
        <div class="tracking-table-container">
          <div class="tracking-table-content table-content">
            <div class="flex items-center space-x-2 jusctify-between pod-container">
              <div class="top-heading">Waybill Number {{ myWaybill }}      
              </div>
              <a *ngIf="hasProofOfDelivery" 
                [routerLink]="['/mainpage/proofofdelivery', myWaybill]" 
                class=" proof-of-delivery">
               <i class="mr-1 fa fa-file-image-o" aria-hidden="true"></i>
               <span class="proofOfDelivery">POD</span>
               </a>
            </div>
           
           
            <div class="table-buttons">
              <span>
                <button
                  class="table-button"
                  printSectionId="printSection"
                  ngxPrint
                  [useExistingCss]="true"
                >
                  <i class="fas fa-print"></i>print
                </button>
              </span>

              <span>
                <button
                  class="table-button"
                  (click)="
                    exporter.exportTable('xlsx', {
                      fileName: 'Top 5',
                      sheet: 'sheet_name',
                      Props: { Author: 'GIGL' }
                    })
                  "
                >
                  <i class="far fa-file-excel"></i>excel
                </button>
              </span>
            </div>
            <!-- TABLE -->
            <div class="table table-container">
              <table
                mat-table
                [dataSource]="dataSource"
                matTableExporter
                #exporter="matTableExporter"
              >
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >SN</mat-header-cell
                  >
                  <mat-cell id="table_cell"  *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>
                <!-- date column -->
                <ng-container matColumnDef="waybill">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >WAYBILL</mat-header-cell
                  >
                  <mat-cell id="table_cell"  *matCellDef="let element" data-label="Waybill">
                    {{ element.Waybill }}
                  </mat-cell>
                </ng-container>

                <!-- service center Column -->
                <ng-container matColumnDef="date">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >DATE</mat-header-cell
                  >
                  <mat-cell id="table_cell"  *matCellDef="let element" data-label="Date">
                    {{ element.DateTime | date }}
                  </mat-cell>
                </ng-container>

                <!-- Destination Column -->
                <ng-container matColumnDef="time">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >TIME</mat-header-cell
                  >
                  <mat-cell id="table_cell"  *matCellDef="let element" data-label="Time">
                    {{ element.DateTime | date : "shortTime" }}
                  </mat-cell>
                </ng-container>
                <!-- Amount column -->
                <ng-container matColumnDef="location">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >LOCATION</mat-header-cell
                  >
                  <mat-cell id="table_cell"  *matCellDef="let element" data-label="Location">
                    {{ element.Location }}
                  </mat-cell>
                </ng-container>
                <!-- partner column -->
                <ng-container matColumnDef="status">
                  <mat-header-cell id="table_header" *matHeaderCellDef
                    >STATUS</mat-header-cell
                  >
                  <mat-cell id="table_cell" *matCellDef="let element" data-label="Status">
                    {{ element.Status }}
                  </mat-cell>
                </ng-container>
                <!-- date column -->
                <!-- <ng-container matColumnDef="scan">
                    <mat-header-cell *matHeaderCellDef>SCANNED BY</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="Scanned by">
                      {{ element.User }}
                    </mat-cell>
                  </ng-container> -->

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <!-- <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row> -->
                <mat-row
                  *matRowDef="let row; let i = index; columns: displayedColumns"
                  [ngClass]="{
                    'even-row': i % 2 === 0,
                    'odd-row': i % 2 !== 0
                  }"
                ></mat-row>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
