// export class DataAccess {
//   setLocalStorageValue(key: any, value: any) {
//     localStorage.setItem(key, JSON.stringify(value));
//   }
//   getLocalStorageValue(key: any) {
//     return JSON.parse(localStorage.getItem(key));
//   }
// }

// replaced the above code with this below : You can provide a default value when accessing the localStorage value if it's null. This ensures that you always have a valid string type.
export class DataAccess {
  setLocalStorageValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getLocalStorageValue(key: string): string {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : ""; // Provide default value
  }
}
