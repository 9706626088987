<!-- <div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div> -->
<!-- <div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div> -->
<div class="container-class">
  <div class="container-content">
    <app-greeting></app-greeting>
    <div class="page-text">
      <h2>Wallet Number: {{ walletNumber }}</h2>
      <h2 class="customer-name">Customer Name: {{ customerName }}</h2>
    </div>
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="print-section"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'COD',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>excel
            
          </button></span
        >
      </div>
    </div>
    <div class="cod-table display-tbl">
      <div class="cod-table-container">
        <div class="cod-table-content table-content">
          <div class="table-filter">
            <i class="fas fa-search"></i>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
            />
          </div>

          <div class="table">
            <table
              id="print-section"
              mat-table
              matTableExporter
              #exporter="matTableExporter"
              [dataSource]="dataSource"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <mat-header-cell id="table_header" *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- items column -->
              <ng-container matColumnDef="date">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >DATE OF ENTRY</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Date">
                  {{ element.DateCreated | date }}
                </mat-cell>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell id="table_header" *matHeaderCellDef>AMOUNT</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Amount">
                  ₦{{ element.Amount | number }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="credit">
                <mat-header-cell id="table_header" *matHeaderCellDef>CREDIT TYPE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Credit Type">
                  {{ element.CreditDebitType }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="status">
                <mat-header-cell id="table_header" *matHeaderCellDef>STATUS</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Status">
                  <span [ngClass] = "element.CODStatus"  class="status-span"> {{ element.CODStatus }}</span>
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="description">
                <mat-header-cell id="table_header" *matHeaderCellDef>DESCRIPTION</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Description">
                  {{ element.Description }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <!-- <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row> -->
              <mat-row
  *matRowDef="let row; let i = index; columns: displayedColumns;"
  [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
></mat-row>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
