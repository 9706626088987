
<div class="container-class tracking">
  <div class="container-content">
    <app-greeting></app-greeting>

    <div class="tracking-information ">
      <div class="tracking-info-section display-card">
        <h4 class="tracking-intro-text">Please Enter Waybill Number</h4>
        <div class="tracking-input">
          <div class="input-container">
            <input type="text" [(ngModel)]="waybill" placeholder="Enter Waybill Number" />
          </div>
          <div class="button-container">
            <button class="track-button" (click)="getToTrackWaybill()">
              Track
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- tracking information -->
    <h2 class="tracking-caption">Tracking information </h2>
    <div class="track-information">
      <div class="tracking-details">
        <div>
          <h3>Waybill Number</h3>
          <p>{{myWaybill}}</p>
        </div>
        <div>
          <h3>Departure Address</h3>
          <p>{{origin}}</p>
        </div>
        <div>
          <h3>Destination Address</h3>
          <p>{{destination}}</p>
        </div>
      </div>
    </div>


    <!-- the display -->
    <div class="tracking-table display-tbl">
      <div class="tracking-table-container">
        <div class="tracking-table-content table-content">
          <div class="top-heading">Waybill Number {{ myWaybill }}</div>
          <div class="table-buttons">
            <span><button class="table-button" 
                printSectionId="print-section"
              ngxPrint
              [useExistingCss]="true" >
                <i class="fas fa-print"></i>print
              </button></span>
            <span><button class="table-button" (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Tracking',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            ">
                <i class="far fa-file-excel"></i>excel
              </button></span>

          </div>
          <div class="table" aria-describedby="table">
            <table aria-label="Tracking Information " id="print-section" mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter" >
             
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                 <th mat-header-cell id="table_header" *matHeaderCellDef>SN</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </td>
              </ng-container>
              <!-- date column -->
              <ng-container matColumnDef="waybill">
                <th mat-header-cell id="table_header" *matHeaderCellDef>WAYBILL</th>
                <td mat-cell *matCellDef="let element" data-label="Waybill">
                  {{ element.Waybill }}
                </td>
              </ng-container>

              <!-- service center Column -->
              <ng-container matColumnDef="date">
                <th mat-header-cell id="table_header" *matHeaderCellDef>DATE</th>
                <td mat-cell  *matCellDef="let element" data-label="Date">
                  {{ element.DateTime | date }}
                </td>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="time">
                <th mat-header-cell id="table_header" *matHeaderCellDef>TIME</th>
                <td mat-cell *matCellDef="let element" data-label="Time">
                  {{ element.DateTime | date: "shortTime" }}
                </td>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="location">
                <th mat-header-cell id="table_header" *matHeaderCellDef>LOCATION</th>
                <td mat-cell *matCellDef="let element" data-label="Location">
                  {{ element.Location }}
                </td>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell id="table_header" *matHeaderCellDef>STATUS</th>
                <td mat-cell *matCellDef="let element" data-label="Status">
                  {{ element.status }}
                </td>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <!-- <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row> -->
              <mat-row
              *matRowDef="let row; let i = index; columns: displayedColumns;"
              [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
            ></mat-row>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>