import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataAccess } from "../helpers/dataAccess";
import { Header } from "../services/tab";

@Component({
  selector: "app-nav-side-bar",
  templateUrl: "./nav-side-bar.component.html",
  styleUrls: ["./nav-side-bar.component.scss"],
})
export class NavSideBarComponent implements OnInit {
  dataAccess = new DataAccess();
  toggle: boolean = false;
  displayInfo: string =
    this.dataAccess.getLocalStorageValue("displayInfo") || "show";
  display: boolean = false;
  displayMobile: boolean = true;
  tabHeader = new Header();
  userCode = this.dataAccess.getLocalStorageValue("userCode");
  change: any = this.dataAccess.getLocalStorageValue("Selection");
  navPage: string = "";
  logout: boolean = false;
  toggleSideBar() {
    this.toggle = !this.toggle;
    this.display = !this.display;
  }
  logOutPopUp() {
    this.logout = true;
  }
  backToNav() {
    this.logout = false;
  }

  toggleMobile() {
    this.displayMobile = !this.displayMobile;
    this.display = !this.display;
  }
  constructor(private router: Router) {}

  getTime() {
    const date = new Date();
    return date;
  }
  // responsible for background color for side bar options
  changeColor(x: any) {
    this.dataAccess.setLocalStorageValue("Selection", x);
    this.change = x = this.dataAccess.getLocalStorageValue("Selection");
    this.navPage = this.tabHeader.tab[this.change];

    // if (this.change == 0) {
    //   this.navPage = 'Dashboard';
    // } else if (this.change == 2) {
    //   this.navPage = 'Transaction History';
    // } else if (this.change == 3) {
    //   this.navPage = 'Wallet';
    // } else if (this.change == 4) {
    //   this.navPage = 'Payment Log';
    // } else if (this.change == 5) {
    //   this.navPage = 'Invoice';
    // } else if (this.change == 6) {
    //   this.navPage = 'Tracking';
    // } else if (this.change == 8) {
    //   this.navPage = 'Cod';
    // } else if (this.change == 7) {
    //   this.navPage = 'Quick Quotes';
    // } else if (this.change == 9) {
    //   this.navPage = 'Shipment Transaction';
    // } else if (this.change == 10) {
    //   this.navPage = 'Profile';
    // } else if (this.change == 11) {
    //   this.navPage = 'Change Password';
    // }
  }

  removeDisplay = () => {
    this.displayInfo = "hide";
    this.dataAccess.setLocalStorageValue("displayInfo", this.displayInfo);
  };
  logOut() {
    localStorage.clear();
    // return false;

    this.router.navigate(["/login"]);
    location.reload();
  }
  changePassword() {
    this.changeColor(11);
    this.router.navigate(["mainpage/changePassword"]);
  }
  profile() {
    this.changeColor(10);
    this.router.navigate(["mainpage/profile"]);
  }
  registerShipment() {
    this.router.navigate(["mainpage/shipmenttransaction"]);
  }

  ngOnInit(): void {
    this.navPage = this.tabHeader.tab[this.change];
  }
}
