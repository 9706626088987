import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requestCount = 0;

  constructor(private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    if(this.requestCount === 1) {
      this.spinner.show();
    }
    return next.handle(request).pipe(
      // finalize()perform cleanup operations on unmanaged resources held by the current object before the object is destroyed.
      finalize(() => {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.spinner.hide();
        }
      })
    );;
  }
}
