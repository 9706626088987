import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCall } from "../services/api-call";
import { MatPaginator } from "@angular/material/paginator";
import { DataAccess } from "../helpers/dataAccess";
import { AuthHelpers } from "../helpers/auth";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"],
})
export class TrackingComponent implements OnInit {

  apiError: boolean = false;
  public errorMessage!: string;
  trackingData: any[] = [];
  tracking: boolean = false;
  dataAccess = new DataAccess();
  spinning: boolean = false;
  waybill!: number;
  waybills!: string;
  waybillss!: string;
  origin!: string ;
  destination!: string;
  status!: string;
  myWaybill!: number;
  dataSource = new MatTableDataSource<any>();

  shipmentTrackings: any[] = [];
  waybillwidth = this.dataAccess.getLocalStorageValue("waybillNumber") || null;
  // responsible for table column
  displayedColumns: string[] = [
    "position",
    "waybill",
    "date",
    "time",
    "location",
    "status",
    
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  waybillNumberInvoice: any;
  constructor(private apiCall: ApiCall, private router: Router, private route: ActivatedRoute,) {

    this.route.params.subscribe(params=>this.waybill = params["waybill"])
 
  }
  closePopUp() {
    this.apiError = false;
  }
  trackShipment() {
    this.spinning = true;
    this.apiCall.getShipmentTracking(this.waybill).subscribe((data) => {
      this.spinning = true;
      if (data.Code == 200) {
        this.spinning = false;
        this.tracking = true;
        this.trackingData = data.Object;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.trackingData);
    });
  }

  trackShipmentWith(waybill: number) {
    this.spinning = true;
    this.apiCall.getShipmentTracking(waybill).subscribe((data) => {
      this.spinning = true;
      if ((data.Code = 200)) {
        this.spinning = false;
        this.tracking = true;
        this.trackingData = data.Object;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.trackingData);
    });
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId: any) {
    this.waybillNumberInvoice = this.trackingData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      "waybillNumberInvoice",
      this.waybillNumberInvoice
    );
    this.router.navigate(["/mainpage/printInvoice"]);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.waybillss = params.get('waybillNumber') || '';
            
      if (this.waybillss) {
        this.getToTrackWaybill();
      }
         this.waybills = params.get('waybillNumber') || '';

    });    
    // if (this.waybillwidth !== null) {

    //   // i commented out the below
    //   // this.trackShipmentWith(this.waybillwidth);
    // }
  }

  getToTrackWaybill(): void {
    this.spinning = true;

    this.apiCall.getTrackShipmentWaybill(this.waybillss).subscribe(  
       (data: any) => {
        
          if (data.Code === "200"){
            this.spinning = false;

            const trackingData = data.Object;
            
            this.origin = trackingData?.Origin;
            this.destination = trackingData?.Destination;
            this.shipmentTrackings = trackingData?.MobileShipmentTrackings || [];
            for (let i = 0; i < this.shipmentTrackings.length; i++) {

              this.myWaybill = this.shipmentTrackings[i].Waybill;
            }
            
            
          //  bind data to table
          this.dataSource.data = this.shipmentTrackings.map((tracking: any) => {
            return {
              Waybill: tracking.Waybill,
              DateTime: tracking.DateTime,
              Location: tracking.Location,
              User: tracking.User,
              status: tracking.ScanStatus.Reason
            };
          })
          
          }else{
            this.apiError = true;
            this.errorMessage = data.ShortDescription;
          }
        },
        (error) => {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = 'An error occurred while tracking the shipment';
          console.error('Error tracking shipment:', error);
        }
       
      
    );
  }
}
