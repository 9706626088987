import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { DataAccess } from "../helpers/dataAccess";
import { ApiCall } from "../services/api-call";
import { MatPaginator } from "@angular/material/paginator";
import { AuthHelpers } from "../helpers/auth";
import { General } from "../helpers/general";
declare var PaystackPop: any;

@Component({
  selector: "app-wallet",
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.scss"],
})
export class WalletComponent implements OnInit {
  apiError!: boolean;
  public errorMessage!: string;
  dataSource!: MatTableDataSource<any>;
  // walletData;
  walletData: any[] = [];

  walletBalance!: number;
  // money;
  public walletNumber: any;
  walletOwnerName!: string;
  // title;
  ussdData: any;
  amount: any;
  dataAccess = new DataAccess();
  userId = this.dataAccess.getLocalStorageValue("userId");
  walletId: any;
  public authHelpers = new AuthHelpers();
  testkey = "pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae";
  testkey_live_Nigeria = "pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b";
  testkey_live_ghana = "pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d";

  phoneNumber = this.authHelpers.getAccessToken("email");
  // countryId: string = localStorage.getItem("countryId");
  countryId = localStorage.getItem("countryId");
  currencySymbol = localStorage.getItem("currencySymbol");
  currencyCode = localStorage.getItem("currencyCode");
  email = this.authHelpers.getAccessToken("email");

  testkey_live = "pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b";
  gatewaycode: any;
  fundWallet: boolean = false;
  paymentOptions: boolean = false;
  fund: boolean = true;
  gatewayCode!: string[];
  pay: boolean = false;
  spinning: boolean = true;
  refCode: string = "WA-" + Math.floor(Math.random() * 1000000000 + 1) + "-WEB";
  ussdPay: boolean = false;

  public general: General = new General();
  // responsible for table column
  displayedColumns: string[] = [
    "sn",
    "date",
    "center",
    "amount",
    "credit",
    "status",
    "description",
    "payment",
    "ref",
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(private apiCall: ApiCall) {}
  closePopUp() {
    this.apiError = false;
  }
  loadWallet() {
    this.apiCall.getWallet().subscribe((data) => {

      this.spinning = true;

      if (data.Code == 200) {
        this.walletData = data.Object.WalletTransactions;
        this.walletBalance = data.Object.WalletBalance;
        this.walletNumber = data.Object.WalletNumber;
        this.currencySymbol = data.Object.CurrencySymbol;
        this.walletId = data.Object.WalletId;
        this.walletOwnerName = data.Object.WalletOwnerName;
        this.spinning = false;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.walletData);
      this.dataSource.paginator = this.paginator;
    });
  }
  fundWalletButton() {
    this.fundWallet = !this.fundWallet;
    this.pay = !this.pay;
    this.fund = !this.fund;
    if (this.paymentOptions) {
      this.paymentOptions = false;
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  closeUssd() {
    this.ussdPay = false;
  }
  paymentLog() {
    if (this.amount == null || this.amount == "") {
      return;
    }
    this.spinning = true;
    const payload = {
      Amount: this.amount,
      IsWalletCredited: false,
      Reference: this.refCode,
      TransactionStatus: null,
      Wallet: {
        WalletBalance: this.walletBalance,
        WalletId: this.walletId,
        WalletNumber: this.walletNumber,
        WalletOwnerName: this.walletOwnerName,
      },
      WalletBalance: this.walletBalance,
      WalletId: this.walletId,
      WalletNumber: this.walletNumber,
      WalletOwnerName: this.walletOwnerName,
    };

    this.apiCall.addwalletpaymentlog(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.payWithPaystack();
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }

  public payWithPaystack() {
    var payStackKey;

    if (this.countryId == "1") {
      payStackKey = this.testkey_live_Nigeria;
    } else if (this.countryId == "76") {
      payStackKey = this.testkey_live_ghana;
    }
    var handler = PaystackPop.setup({
      key: payStackKey,
      email: this.email,
      ref: "" + this.refCode,
      amount: this.amount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response: any) {
        location.reload();
      },
      onClose: function () {},
      onSuccess: function () {
        location.reload();
      },
    });
    handler.openIframe();
  }

  amountChange(event: KeyboardEvent) {
    if (
      this.amount == null ||
      (event.target as HTMLInputElement).value.length == 0
    ) {
      this.paymentOptions = false;
    }
  }

  fundNow() {
    if (this.amount == null) {
      this.paymentOptions = false;
      return;
    }
    this.paymentOptions = true;
  }

  onOptionsSelected(event: Event) {}

  openUssd() {
    this.ussdPay = true;
  }

  payWithUssd() {
    const payload = {
      Amount: 9000,
      PaymentCountryId: 1,
      Email: "it@giglogistics.com",
      ExternalReference: "String",
      GatewayCode: this.gatewaycode,
      OnlinePaymentType: "USSD",
      PhoneNumber: "2349087339777",
      Reference: this.refCode,
      UserId: this.userId,
      WalletId: this.walletId,
    };

    this.apiCall.initiateUSSD(payload).subscribe((data) => {
      this.ussdData = data.Object.data.Dialing_Code;
    });
  }
  ngOnInit(): void {
    this.loadWallet();
  }
}
