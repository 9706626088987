<div class="overlay" *ngIf="spinning">
  <div class="center">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="container-class">
  <h1>Reset your Password</h1>
  <div class="change-password-form">
    <!-- <form [formGroup]="changePasswordForm" (submit)="submit($event)"> -->
    <!-- ADDED -->
    <form [formGroup]="changePasswordForm" (submit)="submit()">
      <div class="form1">
        <label for="password">Current Password</label>
        <input
          formControlName="current_password"
          id="current_password"
          type="password"
          placeholder="Enter current Password"
          class="form-input"
          size="40"
          [(ngModel)]="changePassword.currentPassword"
          [class.inValid]="
            changePasswordForm.get('current_password')?.invalid &&
            changePasswordForm.get('current_password')?.touched
          "
        />
        <small
          class="error"
          [class.none]="
            changePasswordForm.get('current_password')?.valid ||
            changePasswordForm.get('current_password')?.untouched
          "
          >Password is Required</small
        >
      </div>
      <div class="form1">
        <label for="password">New Password</label>
        <input
          formControlName="password"
          id="password"
          type="password"
          placeholder="Enter new Password"
          class="form-input"
          [(ngModel)]="changePassword.newPassword"
          size="40"
          [class.inValid]="
            changePasswordForm.get('password')?.invalid &&
            changePasswordForm.get('password')?.touched
          "
        />
        <small
          class="error"
          [class.none]="
            changePasswordForm.get('password')?.valid ||
            changePasswordForm.get('password')?.untouched
          "
          >Password is Required</small
        >
      </div>
      <div class="form1">
        <label for="confirm_password">Confirm Password</label>

        <!-- <input
          formControlName="confirm_password"
          id="confirm_password"
          type="password"
          class="form-input"
          placeholder="Confirm new password"
          size="40"
          [class.inValid]="changePasswordForm.errors?.misMatch"
        /> ***prev code -->
        <!-- i added the ['misMatch'] -->
        <input
          formControlName="confirm_password"
          id="confirm_password"
          type="password"
          class="form-input"
          placeholder="Confirm new password"
          size="40"
          [class.inValid]="changePasswordForm.errors?.['misMatch']"
        />

        <small
          class="error"
          [class.none]="
            changePasswordForm.get('confirm_password')?.valid ||
            changePasswordForm.get('confirm_password')?.untouched
          "
          >Please Confirm Password</small
        >
        <!-- <div *ngIf="changePasswordForm.errors?.misMatch" class="error">  **replaced the above comment with ['misMatch']  -->
        <div *ngIf="changePasswordForm.errors?.['misMatch']" class="error">
          Password and Confirm Password must be match.
        </div>
      </div>
      <button
        class="form-btn"
        type="submit"
        [disabled]="!changePasswordForm.valid"
      >
        Reset My Password
      </button>
    </form>
    <div
      class="errorMsg"
      style="text-align: center; font-weight: 700; color: red"
    >
      {{ errorMessage }}
    </div>
  </div>
  <div class="overlay" *ngIf="passwordChange">
    <div class="password-message">
      <div class="message">Password Changed</div>
      <button (click)="returnLogin()">Ok</button>
    </div>
  </div>
</div>
