<div class="container-class tracking">
  <div class="container-content transaction-content">
    <!-- <app-greeting></app-greeting> -->
    <div class="display-card transaction-options">
      <h4 class="transaction-text">Search By Date Range</h4>
      <div class="transaction-input-content">
        <div class="transaction-input-container">
          <label for="">Start Date:</label>
          <input
            class="input-class"
            type="date"
            [(ngModel)]="shipmentModel.StartDate"
          />
        </div>
        <div class="transaction-input-container">
          <label for="">End Date:</label>
          <input
            class="input-class"
            type="date"
            [(ngModel)]="shipmentModel.EndDate"
          />
        </div>
        <div class="transaction-input-container">
          <button class="button button-submit" (click)="searchShipments()">
            SEARCH
          </button>
        </div>
      </div>
    </div>
    <!-- print excel button -->
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="print-section"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>Print
          </button>
        </span>
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'AllShipment History',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>Excel
          </button></span
        >
      </div>
    </div>
    <!-- table -->
    <div class="table-content transaction-table">
      <div class="transaction-table-content-header table-header"></div>
      <div class="transaction-table-content">
        <div class="table-filter">
          <i class="fas fa-search"></i>
          <input
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Search"
            matTooltip="Enter Waybill, Sender/Receiver names separated by commas"
            aria-label="Search by waybill, sender or receiver name "
          />
        </div>
        
        <!-- TABLE -->
        <div class="table table-container">
          <table
            id="print-section"
            mat-table
            matSort
            matTableExporter
            #exporter="matTableExporter"
            [dataSource]="dataSource"
          >
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >SN</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element; let i = index"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>
            <!-- items column -->
            <ng-container matColumnDef="waybill">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >WAYBILL</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Waybill"
              >
                {{ element.Waybill }}
              </mat-cell>
            </ng-container>

            <!-- Sender Column -->
            <ng-container matColumnDef="sender">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >SENDER
              </mat-header-cell>
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Sender"
              >
                {{ element.Sender }}
              </mat-cell>
            </ng-container>
            <!-- Departure Column -->
            <ng-container matColumnDef="departure">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >DEPARTURE</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Departure"
              >
                {{ element.DepartureServiceCentre }}
              </mat-cell>
            </ng-container>

            <!-- Destination Address Column -->
            <ng-container matColumnDef="destinationaddress">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >DESTINATION ADDRESS</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Destinationaddress"
              >
                {{ element.ReceiverAddress }}
              </mat-cell>
            </ng-container>

            <!-- Amount column -->
            <ng-container matColumnDef="amount">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >AMOUNT</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Amount"
              >
                {{ element.Amount ? "₦ " + (element.Amount | number) : "" }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="receivername">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >RECEIVER NAME</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="receivername"
              >
                {{ element.CustomerDetails }}
              </mat-cell>
            </ng-container>

            
            
            <!-- STATUS -->

            <ng-container matColumnDef="currentstatus">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >CURRENT STATUS</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="currentstatus"
              >
                {{ element.LatestStatus?.ScanStatus?.Reason || "" }}
              </mat-cell>
            </ng-container>

            <!-- date column -->
            <ng-container matColumnDef="datetime">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >DATE</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element"
                data-label="Date"
              >
                {{ element.DateCreated | date : "short" }}
              </mat-cell>
            </ng-container>
            
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell id="table_header" *matHeaderCellDef
                >ACTIONS</mat-header-cell
              >
              <mat-cell
                id="table_cell"
                *matCellDef="let element; let i = index"
                class="track"
              >
              <div class="stacked-spans">

                <span
                  id="trackBtn"
                  class="track-button"
                  (click)="trackWaybill(i)"
                >
                  <!-- to pass the Waybill directly to the function. -->
                  <i class="fas fa-map-marker-alt"></i>
                  <span class="track-text">Track</span>
                </span>
 
                <span 
                *ngIf="element.HasProofOfDelivery"
                class="bypod"  (click)="navigateToProofOfDelivery(element.Waybill)">
                  
                  <i class="fa fa-file-image-o" aria-hidden="true"></i>
                  <span class="proofOfDelivery">POD</span>
                </span>
              </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <!-- <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row> -->
            <mat-row
              *matRowDef="let row; let i = index; columns: displayedColumns"
              [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
            ></mat-row>
          </table>

          <mat-paginator
            [pageSizeOptions]="[100, 200, 500]"
            showFirstLastButtons
            (page)="handlePageEvent($event)"
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
