import { Component, OnInit } from "@angular/core";
import { ApiCall } from "../services/api-call";
import { MatDialog } from "@angular/material/dialog";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-process-terminal-shipment",
  templateUrl: "./process-terminal-shipment.component.html",
  styleUrls: ["./process-terminal-shipment.component.scss"],
})
export class ProcessTerminalShipmentComponent implements OnInit {
  
  apiError: boolean = false;
  constructor(private apiCall: ApiCall, private dialog: MatDialog) {}
  waybill: any;

  processTerminalShipmentData: any[] = [];
  spinning: boolean = false;
  message!: string;
  submit: boolean = false;
  reload() {
    this.submit = false;
    location.reload();
  }

  processPayment(i: any) {
    this.spinning = true;
    this.waybill = this.processTerminalShipmentData[i].Waybill;
    this.apiCall.payForShipment(this.waybill).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.submit = true;
        this.message = data.ShortDescription;
      } else {
        this.spinning = false;
        this.submit = true;
        this.message = data.ShortDescription;
      }
    });
  }

  ngOnInit(): void {
    this.spinning = true;
    this.apiCall.getProcessTerminalShipment().subscribe((data) => {
      this.spinning = false;

      if (data.Code == 200  && data.Object.length === 0) {
        this.openNoPendingShipmentModal();
        // this.spinning = false;
      } else {

        this.processTerminalShipmentData = data.Object;
      }
    });
  }
  openNoPendingShipmentModal() {
    this.dialog.open(ModalComponent, {
      width: '300px'
    });
  }

}
