
<div class=" container-class login-page">
  <div class="container-content">
    <div class="image">
        <img src="../../assets/images/backdrop.png" alt="">
    </div>
    <div class="content">
      <div class="login-form">
        <div class="logo">
          <img src="./assets/images/GIGLogin.png" alt="" />
          <h3 class="header">Merchant Portal</h3>
        </div>
        <div class="form-group">
         <form  (submit)="SubmitLogin($event)" action="">
           <div class="flex">
             <label>Username</label>
             <input type="text"  name="Username"
             type="text"
             [(ngModel)]="LoginModel.username">
           </div>
           <div class="flex">
            <label>Password</label>
            <div class="password-field">

              <input  [type]="passwordVisible ? 'text' : 'password'" type="password"  name="Password"
              [(ngModel)]="LoginModel.password"
              
              >
           
              <i class="toggle-visibility" (click)="togglePasswordVisibility()" [class]="passwordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
          
            </div>
          </div>
          <div class="checkbox-text">
            <div class="checkbox-container">
              <div>
              <input type="checkbox"  id="rememberme"
              onclick="getCheckedValue('rememberme')">
            </div>
              <div>
                <label>Remember Me</label>
              </div>
           
            </div>
          
            <div>
              <span class="forgot"  (click)="forgotPassword()">Forgot Password?</span>
            </div>
          </div>
          <div class="button-container">
            <button  type="submit">Login into your account</button>
          </div>
          <div style="text-align: center; color: rgb(236, 62, 18); margin-top: 10px;">  {{ errorMessage }}</div>
         </form>
         <div class="signup-text">
          <!-- <div class="container-text"><span>Do not have an account ? </span> <span class="register-text"><a target="_blank" href="https://giglogistics.com/merchant-form">Register Now</a></span></div> -->
          <div class="container-text"><span>Do not have an account ? </span> <span class="register-text"><a target="_blank" href="https://gigl-go.com/merchantSignUp">Register Now</a></span></div>
         </div>
         <div class="push-down">
           <i>Powering centralized and decentralized commerce</i>
         </div>
        </div>
      </div>
    </div>
  </div>
 
</div>

<!-- <div id="login-page-full">
  <div id="login-full-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div id="login-box">
            <div id="login-box-holder">
              <div class="row">
                <div class="col-xs-12">
                  <header id="login-header">
                    <div id="login-logo">
                      <img src="./assets/images/GIGLogin.png" alt="" />
                      <img src="./assets/images/GIGLoginLine.png" alt="" />
                    </div>
               
                  <div id="login-box-inner" class="ngdialog-theme-default">
                    <form (submit)="SubmitLogin($event)">
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          name="Username"
                          type="text"
                          [(ngModel)]="LoginModel.username"
                          placeholder="Email address"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-key"></i
                        ></span>
                        <input
                          type="password"
                          name="Password"
                          [(ngModel)]="LoginModel.password"
                          class="form-control"
                          placeholder="Password"
                        />
                      </div>
                      <div id="remember-me-wrapper">
                        <div class="row">
                          <div class="col-xs-6">
                            <div class="checkbox-nice">
                              <input
                                type="checkbox"
                                id="rememberme"
                                onclick="getCheckedValue('rememberme');"
                              />
                              <input
                                type="hidden"
                                name="hremb"
                                id="hremb"
                                value=""
                              />
                              <label for="remember-me">
                                Remember me
                              </label>
                            </div>
                          </div>
                          <span
                            (click)="forgotPassword()"
                            id="login-forget-link"
                            class="col-xs-6 forgot-password"
                          >
                            Forgot password?
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <a href="./app/app.component.html"
                            ><button
                              type="submit"
                              class="btn btn-danger col-xs-12"
                            >
                              Login
                            </button></a
                          >
                          <div
                            style="
                              margin-top: 60;
                              text-align: center;
                              font-weight: 700;
                              color: red;
                            "
                          >
                            {{ errorMessage }}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
