import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApiCall } from "../services/api-call";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { ShipmentApiResponse, Shipments } from "../models/shipments";
import { DataAccess } from "../helpers/dataAccess";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-shipment",
  templateUrl: "./shipment.component.html",
  styleUrls: ["./shipment.component.scss"],
})
export class ShipmentComponent {
  shipments: Shipments[] = [];
  dataSource: MatTableDataSource<Shipments> = new MatTableDataSource(); 

  spinning: boolean = false;
  apiError: boolean = false;
  public errorMessage!: string;
  waybillNumber!: any;
  dataAccess = new DataAccess();

  StartDate!: string;
  EndDate!: string;
  CustomerCode!: string;
  PageSize: number = 500;
  CurrentPage!: number;

  public shipmentModel = { StartDate: '', EndDate: '' };

  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = [
    "position",
    "waybill",
    "sender",
    "departure",
    "destinationaddress",
    "amount",
    "receivername",
    // "pod",
    "currentstatus",
    "datetime",
    "actions",
  ];

  constructor(private apiCall: ApiCall, private router: Router, private toastr: ToastrService) {}


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator; 
    this.loadShipments();
  }

  // Track waybill function
  trackWaybill(i:any) {
    const waybillNumber = this.shipments[i].Waybill;
    localStorage.setItem("waybillNumber", waybillNumber);
    this.router.navigate([`/mainpage/shipmenttracking/${waybillNumber}`]);
  }


  // Apply filter function for search input
  applyFilter(event: Event) {
   
    const filterValue = (event.target as HTMLInputElement).value.trim();
    if (!filterValue) {
      this.dataSource.data = this.shipments; // Reset the table data
      return;
    }
    console.log(this.shipments)
    // this  search by waybill and sender name
    const searchTerms = filterValue.split(',').map((term) => term.trim().toLowerCase());
    this.dataSource.data = this.shipments.filter((shipment) => {
      const waybillMatch = searchTerms.some((term) =>
        shipment.Waybill?.toLowerCase().includes(term)
      );
      const senderMatch = searchTerms.some((term) =>
        shipment.Sender?.toLowerCase().includes(term)
      );
      const ReceiverAddressMatch = searchTerms.some((term) =>
        shipment.ReceiverAddress?.toLowerCase().includes(term)
      );
      const customersMatch = searchTerms.some((term) =>
        shipment.CustomerDetails?.toLowerCase().includes(term)
      );
      const DepartureServiceCentreMatch = searchTerms.some((term) =>
        shipment.DepartureServiceCentre?.toLowerCase().includes(term)
      );
      // const DestinationCentreMatch = searchTerms.some((term) =>
      //   shipment.DestinationServiceCentre?.toLowerCase().includes(term)
      // );
      return waybillMatch || senderMatch || customersMatch || ReceiverAddressMatch || DepartureServiceCentreMatch;
    });
  }

  closePopUp() {
    this.apiError = false;
  }
  

// data populating the table on component mount
loadShipments(): void {
    const { StartDate, EndDate } = this.shipmentModel;
    this.apiCall
      .getAllShipments(
        this.CurrentPage,
        this.PageSize,
       StartDate || '', EndDate || ''
     
      )
      .subscribe(
        (response) => {
          const responseObject = response.Object || {};
          this.shipments = responseObject?.Shipments.map((shipment: any) => ({
            Waybill: shipment.Waybill,
            Sender: shipment.Sender,
            DepartureServiceCentre: shipment.DepartureServiceCentre.Name,
            DestinationServiceCentre: shipment.DestinationServiceCentre,
            ReceiverAddress: shipment.ReceiverAddress,
            Amount: shipment.Amount,
            CustomerDetails: shipment.CustomerDetails,
            DateCreated: shipment.DateCreated,
            HasProofOfDelivery: shipment.HasProofOfDelivery,
            LatestStatus: {
              Status: shipment.LatestStatus?.Status || null,
              ScanStatus: {
                Reason: shipment.LatestStatus?.ScanStatus?.Reason || null
              }
            }
          })) || [];
          this.dataSource.data = this.shipments;    
            },
        (error) => {
          console.error("error fetching shipments:", error);
          this.toastr.error("Failed to fetch shipments. Please try again later.");

        }
      );
  }

  // Trigger loadShipments when the Search button is clicked
  searchShipments(): void {
    if (!this.shipmentModel.StartDate || !this.shipmentModel.EndDate) {
      this.toastr.warning("Please select both Start Date and End Date.");
      return;
    }
    this.CurrentPage = 1; // Reset to the first page for a new search
    this.loadShipments();
  }

    // Handle page change (Pagination)
    handlePageEvent(event: any): void {
      this.PageSize = event.pageSize;
      this.CurrentPage = event.pageIndex + 1;
      this.loadShipments();
    }


    // Track by Proof of Delivery
    navigateToProofOfDelivery(waybillNumber: string) {
      if (!waybillNumber) {
        this.toastr.error('Waybill number is required.');
        return;
      }
      this.router.navigate(['/mainpage/proofofdelivery', waybillNumber]); 
    }
    
}
