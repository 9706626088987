<!-- <div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp2()">Ok</button>
  </div>
</div> -->
<div class="container-class">
  <div class="container-content">
    <!-- <app-greeting></app-greeting> -->
    <app-custom-text [text]="'Wallet Payment-Logs'">
      
    </app-custom-text>
    <!-- <div class="page-text">
      <h2>Wallet Payment-Logs</h2>
    </div> -->
    <div class="payment-log-table display-tbl">
      <div class="payment-log-table-container">
        <div class="payment-log-table-content table-content">
          <div class="table-container">
            <table mat-table [dataSource]="dataSource">
              <!-- Position Column -->
              <ng-container matColumnDef="reference">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >REFERENCE</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="REFERENCE">
                  {{ element.Reference }}
                </mat-cell>
              </ng-container>
              <!-- items column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >AMOUNT</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="AMOUNT">
                  ₦ {{ element.Amount | number }}
                </mat-cell>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >STATUS</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="STATUS">
                  <button
                    class="btn-status"
                    [ngClass]="element.TransactionStatus"
                    [ngStyle]="{
                      display:
                        element.TransactionStatus == null ? 'none' : 'block'
                    }"
                  >
                    {{ element.TransactionStatus }}
                  </button>
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >DESCRIPTION</mat-header-cell
                >
                <mat-cell
                  id="table_cell"
                  *matCellDef="let element; let i = index"
                  data-label="DESCRIPTION"
                >
                  {{ element.TransactionResponse }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="date">
                <mat-header-cell id="table_header" *matHeaderCellDef
                  >DATE</mat-header-cell
                >
                <mat-cell
                  id="table_cell"
                  *matCellDef="let element"
                  data-label="DATE"
                >
                  {{ element.DateCreated | date }}
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="validation">
                <mat-header-cell
                  id="table_header"
                  *matHeaderCellDef
                ></mat-header-cell>
                <mat-cell
                  id="table_cell"
                  *matCellDef="let element; let i = index"
                  data-label=""
                >
                  <span
                    *ngIf="
                      element.TransactionResponse == null ||
                      element.TransactionStatus == 'PENDING'
                    "
                    ><button
                      class="revalidate-button"
                      (click)="verifyPayment(i)"
                    >
                      Revalidate
                    </button></span
                  >
                </mat-cell>
              </ng-container>
              <!-- date column -->

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <!-- <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row> -->
              <mat-row
                *matRowDef="let row; let i = index; columns: displayedColumns"
                [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
              ></mat-row>
            </table>
            <!-- <mat-paginator
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
            ></mat-paginator> -->
          </div>
        </div>
        <div class="overlay" *ngIf="revalidate">
          <div class="validation-message">
            <div class="message">{{ message }}. {{ gatewayMessage }}</div>
            <button (click)="closePopUp()">Ok</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
