
<div class="container-class">
  <div class="container-content">
    <div class="part-one">
      <button class="invoice-button" (click)="returnToInvoicePage()">
        <i class="glyphicon glyphicon-list-alt"></i>view invoice
      </button>
      <div class="page-text">
        <h2>Invoice: {{ invoiceNo }}</h2>
      </div>
    </div>
    <div class="part-two">
      <button
        class="print"
        printSectionId="print-section"
        ngxPrint
        [useExistingCss]="true"
      >
        <i class="fa fa-print"></i>print
      </button>
      <button class="send-invoice-button">
        <i class="fa fa-mail-forward fa-lg"></i>send invoice
      </button>
    </div>
    <div class="display-tbl">
      <div class="invoice-table-container" id="print-section">
        <div class="table-content">
          <div class="table-container-text">
            GIG LOGISTICS
          </div>
          <div class="print-information">
            <div class="print-information-one">
              <span class="item-list"
                ><img
                  src="./assets/images/GIGLogin.png"
                  width="70"
                  alt=""
              /></span>
              <span class="item-list"
                >Payment Mode
                <div>{{ paymentMode }}</div>
              </span>
              <span class="item-list"
                >Cash On Delivery
                <div>₦{{ cashOnDelivery | number }}</div>
              </span>
              <span class="item-list"
                >Date of Shipment
                <div>{{ date | date }}</div>
              </span>
              <span class="item-list"
                >Invoice No
                <div>{{ invoiceNo }}</div>
              </span>
              <span class="item-list"
                >Waybill #:
                <div>{{ waybillNumber }}</div>
              </span>
              <span class="item-list">
                <div>{{ paymentStatus }}</div>
              </span>
              <span class="item-list"
                >Customer Category:
                <div>{{ category }}</div>
              </span>
              <span class="item-list"
                >Declared Value
                <div>₦ {{ declaredValue | number }}</div>
              </span>
            </div>
            <div class="print-information-two">
              <div class="sender">
                From
                <div class="sender-name">{{ senderName }}</div>
                <div class="sender-address">{{ senderAddress }}</div>
                <div class="sender-number">{{ senderNumber }}</div>
              </div>
              <div class="receiver">
                To
                <div class="receiver-name">{{ receiverName }}</div>
                <div class="receiver-address">{{ receiverAddress }}</div>
                <div class="receiver-number">{{ receiverNumber }}</div>
              </div>
              <div class="sign">
                <img
                  src="../../assets/images/collectioninfo.png"
                  alt=""
                />
              </div>
            </div>
            <div class="print-information-three">
              <div class="table">
                <table mat-table [dataSource]="dataSource">
                  <!-- Position Column -->
                  <ng-container matColumnDef="position">
                    <mat-header-cell *matHeaderCellDef>CHECK</mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                      <input type="checkbox" /> {{ i - 1 }}
                    </mat-cell>
                  </ng-container>
                  <!-- items column -->
                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef
                      >DESCRIPTION</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element"
                      >{{ element.Description }}
                    </mat-cell>
                  </ng-container>

                  <!-- nature Column -->
                  <ng-container matColumnDef="nature">
                    <mat-header-cell *matHeaderCellDef
                      >NATURE OF ITEM</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                      {{ element.Nature }}</mat-cell
                    >
                  </ng-container>

                  <!-- quantity Column -->
                  <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef
                      >QUANTITY</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                      {{ element.Quantity }}</mat-cell
                    >
                  </ng-container>
                  <!-- unit column -->
                  <ng-container matColumnDef="unit">
                    <mat-header-cell *matHeaderCellDef
                      >UNIT PRICE</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                      {{ element.Price }}</mat-cell
                    >
                  </ng-container>
                  <!-- amount column -->
                  <ng-container matColumnDef="amount">
                    <mat-header-cell *matHeaderCellDef>AMOUNT</mat-header-cell>
                    <mat-cell *matCellDef="let element"
                      >₦{{ element.Price }}
                    </mat-cell>
                  </ng-container>
                  <!-- total column -->
                  <ng-container matColumnDef="total">
                    <mat-header-cell *matHeaderCellDef>TOTAL</mat-header-cell>
                    <mat-cell *matCellDef="let element" data-label="total">
                      ₦ {{ element.Price * element.Quantity }}
                    </mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                  ></mat-header-row>
                  <!-- <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                  ></mat-row> -->
                  <mat-row
                  *matRowDef="let row; let i = index; columns: displayedColumns;"
                  [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
                ></mat-row>
                </table>
              </div>
              <div class="table-information">
                <span class="prepared">Prepared By: {{ preparedBy }}</span>
                <div class="prices">
                  <span>On Discount: ₦ {{ discount }}</span>
                  <span>Vat: ₦ {{ vat }}</span>
                  <span>Off Discount: ₦ {{ offDiscount }}</span>
                  <span>Total: ₦ {{ totalMoney | number }}</span>
                </div>
              </div>
            </div>
            <div class="grand-total">
              Grand Total: ₦ {{ grandTotal | number }}
            </div>
            <div class="print-information-four">
              <div class="terms-conditions">
                <h2>Terms and Conditions</h2>
                <small>
                  <p>
                    1. No variations of conditions The handling of the goods
                    shall be subject to the conditions stated herein unless
                    specifically varied by the Carrier. 2. Charges and Billing
                    2.1. The consideration payable to the Carrier by the Shipper
                    for the handling of goods and related services, will be in
                    accordance with the standard tariffs of the Carrier, to be
                    paid as determined by the Carrier from time to time. 2.2.
                    The Carrier's standard tariffs are available on request.
                    2.3. Shipment not claimed within 3 working days of its
                    arrival shall attract a daily demurrage charge. 3.
                    Conditions of goods 3.1. The onus of proving the quantity,
                    type, physical properties and composition and the condition
                    of the goods and/or the condition of any container at the
                    time of receipt thereof by the Carrier shall at all times
                    remain with the Shipper, and no delivery note, receipt or
                    other document furnished or signed at such time by or on
                    behalf of the Carrier shall constitute conclusive proof
                    thereof. 4. Right to inspection 4.1. The Carrier has a right
                    to inspect the goods without prior notice to the Shipper,
                    which includes the right to open and examine the goods. 4.2.
                    Receiver is to ensure 100% inspection of goods, acknowledge
                    condition of goods before departure at point of collection
                    from courier. 5. Deliveries &amp; Undeliverables 5.1.
                    Shipments shall not be delivered to Postal codes, but to the
                    Receiver’s address given by the Shipper (which in the case
                    of mail services shall be deemed to be the first receiving
                    postal service and in the case of a central receiving area
                    will be delivered to that area), not necessarily to the
                    named Receiver personally. If the Shipment is deemed to be
                    unacceptable, or Receiver cannot be reasonably identified,
                    GIG Logistics shall use reasonable efforts to return the
                    Shipment to Shipper at Shipper’s cost, failing which the
                    Shipment may be disposed of by GIG Logistics without
                    incurring any liability whatsoever. 6. Carrier's lien 6.1.
                    As security for money owed for the handling of the goods,
                    the Carrier shall have a lien over all goods in its
                    possession or under its control. 6.2. If any money owed to
                    the Carrier is not paid by the Shipper within 30 days after
                    they have become due, the Carrier shall be entitled without
                    further notice to dispose of such goods in any way
                    whatsoever. 7. Shipper's warranties The Shipper shall
                    indemnify and hold the Carrier harmless for any loss or
                    damage arising out of the Shipper’s failure to comply with
                    any applicable laws or regulations, and the for the
                    Shipper’s breach of the following warranties and
                    representations: 7.1. All information, descriptions, values
                    and other particulars furnished to the Carrier is complete
                    and accurate; 7.2. The goods are the Shipper's sole property
                    or the Shipper is authorized by the person owning the goods
                    to enter into this agreement; 7.3. The waybill has been
                    signed by the Shipper’s authorized representative and the
                    terms and conditions in this Agreement constitute binding
                    and enforceable obligations on the Shipper; 7.4. The
                    shipment is properly marked, addressed and packed to ensure
                    safe transportation with ordinary care in handling; and 7.5.
                    All applicable customs, import, export and other laws and
                    regulations have been complied with. 8. Dangerous goods 8.1.
                    Shipper warrants that all goods handled are fit to be so
                    handled in the ordinary way and are not dangerous goods;\
                    8.2. The Carrier will not handle any dangerous, corrosive,
                    noxious, hazardous, inflammable or explosive goods or any
                    goods which in its opinion are likely to cause damage; 8.3.
                    The Shipper shall be liable for all losses or damage caused
                    to the Carrier and/or third parties by all dangerous goods;
                    8.4. If, in the opinion of the Carrier any goods become a
                    danger to any person or property, the Carrier shall be
                    entitled to and without notice to the Shipper take such any
                    steps as it in its sole discretion in respect of the goods.
                    In such event the Carrier shall: 8.4.1. not be liable under
                    any circumstances for the value of the goods or for any
                    other loss or damage sustained by the Shipper or owner as a
                    result of such steps; and 8.4.2. still be entitled to
                    recover from the Shipper its remuneration for the handling
                    of the goods together with any costs incurred by the
                    Carrier. 9. Perishable goods 9.1. Perishable goods which are
                    not taken up immediately upon arrival at their destination
                    or which are insufficiently marked or otherwise not
                    identifiable may be disposed of without notice to Shipper.
                    10. Route 10.1. When carrying goods, the Carrier shall in
                    its sole discretion decide what route to follow. 11.
                    Carrier's obligations 11.1. It will be deemed that the
                    Carrier has discharged all its obligations in terms hereof,
                    once the Carrier has delivered or handed the goods to the
                    receiver stated in the waybill at the receiver's address or
                    at any other address the Carrier has been requested to
                    deliver to. 12. Carrier's liability for damage or loss 12.1.
                    The Carrier will only be liable for direct loss and damage
                    only and this shall be limited to the sum of N1, 000.00 (One
                    Thousand Naira) only. All other types of loss or damage are
                    excluded and this includes but is not limited to lost
                    profits, income, interest, and future business. The Carrier
                    shall not be liable for any loss or damage that is special
                    or indirect, even if the risks (financial or otherwise)
                    associated with such loss or damage was communicated
                    directly or indirectly to the Carrier before or after the
                    acceptance of the shipment. 12.2. Claims are limited to one
                    claim per shipment, settlement of which will be full and
                    final settlement for all loss or damage in connection
                    therewith. 12.3. If the Shipper regards the limits provided
                    in Clauses 12.1 and 12.2 as insufficient, then the Shipper
                    must make a special declaration of value and take out
                    insurance on the shipment as directed by the Carrier’s staff
                    so as to benefit from the recovery of a larger sum subject
                    to the investigation and subsequent approval by the
                    Carrier’s management. 12.4. All claims must be submitted in
                    writing to GIG Logistics within thirty (30) days from the
                    date that GIG Logistics accepted the shipment, failing which
                    GIG Logistics shall have no liability whatsoever. 13.
                    Subcontracting 13.1. The Carrier reserves the right to
                    employ sub-contractors to act for it, and shall have no
                    responsibility or liability to the Shipper for any acts or
                    omissions of such third parties. 14. Applicable law 14.1.
                    The applicable Law regarding this agreement are the Laws of
                    the Federal Republic of Nigeria. 15. Notices 15.1. Notices
                    shall be served on the address stipulated on the waybill.
                    16. General Terms 16.1. These terms constitutes the sole
                    record of the agreement between the parties. The Carrier
                    shall not be bound by any express or implied term,
                    representation, warranty, promise or the like not recorded
                    herein. 16.2. No relaxation or indulgence which the Carrier
                    may grant to the Shipper shall constitute a waiver of the
                    rights of the Carrier regardless of when it arose.
                  </p>
                </small>
              </div>
            </div>
            <div class="print-information-five">
              <div class="prepared-by">Prepared By: {{ preparedBy }}</div>
              <div class="gig-info">
                <span>Tel: 0813981120</span>
                <span>Website: www.giglogistics.com</span>
                <span>Email: info@giglogistics.com.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
