import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private customerNameSource = new BehaviorSubject<string>(''); 
  currentCustomerName = this.customerNameSource.asObservable();
  
  constructor() { }

  updateCustomerName(name: string) {
    this.customerNameSource.next(name);
  }
}
