import { Component, OnInit } from "@angular/core";
import { ConfirmedValidator } from "../services/confirmed.validator";
import { FormBuilder, Validators } from "@angular/forms";
import { ChangePassword } from "../models/changePassword";
import { ApiCall } from "../services/api-call";
import { DataAccess } from "../helpers/dataAccess";
import { Router } from "@angular/router";
@Component({
  selector: "app-password-change",
  templateUrl: "./password-change.component.html",
  styleUrls: ["./password-change.component.scss"],
})
export class PasswordChangeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private apiCall: ApiCall,
    private router: Router
  ) {}
  public changePassword = new ChangePassword();
  dataAccess = new DataAccess();
  userId = this.dataAccess.getLocalStorageValue("userId");
  passwordChange: boolean = false;
  spinning: boolean = false;
  public errorMessage!: string;

  // form builder
  changePasswordForm = this.fb.group(
    {
      current_password: ["", [Validators.required]],
      password: ["", [Validators.required]],
      confirm_password: ["", [Validators.required]],
    },
    { validator: ConfirmedValidator }
  );

  // responsible for submission of form
  // submit(e) {
  submit() {
    this.changePassword.userid = this.userId;
    this.spinning = true;
    this.apiCall.getChangePassword(this.changePassword).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.passwordChange = true;
      } else {
        this.spinning = false;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  // returns to login page
  returnLogin() {
    this.router.navigate(["/login"]);
    localStorage.clear();
  }

  ngOnInit(): void {}
}
