<div class="container-class contain">
  <div class="flex-1 sideBar contain-sidebar" [ngClass]="{ showVal: display }">
    <div class="sidebar-content">
      <div class="brand-title">
        <span class="gig">GIG</span><span class="log">Logistics</span>
      </div>

      <div class="sidebar-links">
        <div class="sidebar-options">
          <span
            class="links"
            [ngClass]="{ active: change === 0, inactive: change != 0 }"
          >
            <a
              routerLink="/mainpage/"
              routerLinkActive="list-item-active"
              (click)="changeColor(0)"
              ><i class="fas fa-columns"></i>Dashboard</a
            >
          </span>
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 1, inactive: change != 1 }"
          >
            <a
              (click)="changeColor(1)"
              routerLink="/mainpage/codreceipthistory"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD Remittances</a
            >
          </span> -->
          <span
            class="links active-link"
            [ngClass]="{ active: change === 1, inactive: change != 1 }"
          >
            <a
              (click)="changeColor(1)"
              routerLink="/mainpage/customertransactions"
              routerLinkActive="list-item-active"
              ><i class="far fa-chart-bar"></i>Transaction History</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 2, inactive: change != 2 }"
          >
            <a
              (click)="changeColor(2)"
              routerLink="/mainpage/walletsummary"
              routerLinkActive="list-item-active"
              ><i class="fas fa-wallet"></i>Wallet</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 3, inactive: change != 3 }"
          >
            <a
              (click)="changeColor(3)"
              routerLink="/mainpage/paymentlog"
              routerLinkActive="list-item-active"
              ><i class="fa fa-credit-card"></i>Payment Logs</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 11, inactive: change != 11 }"
          >
            <a
              (click)="changeColor(11)"
              routerLink="/mainpage/processterminalshipment"
              routerLinkActive="list-item-active"
              ><i class="fas fa-credit-card"></i>Process terminal shipment</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 4, inactive: change != 4 }"
          >
            <a
              (click)="changeColor(4)"
              routerLink="/mainpage/invoice"
              routerLinkActive="list-item-active"
              ><i class="fas fa-briefcase"></i>Invoice</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 5, inactive: change != 5 }"
            ><a
              (click)="changeColor(5)"
              routerLink="/mainpage/shipmenttracking"
              routerLinkActive="list-item-active"
              ><i class="fa fa-map-marker"></i> Track Shipment</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 6, inactive: change != 6 }"
            ><a
              (click)="changeColor(6)"
              routerLink="/mainpage/quickquote"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Quick Quotes</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 7, inactive: change != 7 }"
            ><a
              (click)="changeColor(7)"
              routerLink="/mainpage/cashondelivery"
              routerLinkActive="list-item-active"
              ><i class="fa fa-money"></i>COD</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 8, inactive: change != 8 }"
            ><a
              (click)="changeColor(8)"
              routerLink="/mainpage/allshipment"
              routerLinkActive="list-item-active"
              >
              <i class="fa fa-truck" ></i>Track Multiple Shipments </a>

              </span
          >
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 8, inactive: change != 8 }"
          >
            <span
              class="shipment-transaction"
              (click)="changeColor(8)"
              (click)="registerShipment()"
              ><i class="fas fa-ship"></i>Oversea Shipping</span
            >
          </span> -->
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 9, inactive: change != 9 }"
            ><a
              (click)="changeColor(9)"
              routerLink="/mainpage/sla"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>SLA</a
            ></span
          > -->
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 10, inactive: change != 10 }"
            ><a
              (click)="changeColor(10)"
              routerLink="/mainpage/createshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-plus"></i>Create Shipment</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 11, inactive: change != 11 }"
            ><a
              (click)="changeColor(11)"
              routerLink="/mainpage/pickuprequest"
              routerLinkActive="list-item-active"
              ><i class="fas fa-people-carry"></i>Pickup request</a
            ></span
          > -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="contain-sidebar-mobile for-mobile"
    [ngClass]="{ showVal: displayMobile }"
  >
    <div class="sidebar-content">
      <div class="brand-title">
        <span class="gig">GIG</span><span class="log">Logistics</span>
        <i (click)="toggleMobile()" class="fa fa-times close-menu"></i>
      </div>

      <div class="sidebar-links">
        <div class="sidebar-options">
          <span class="links">
            <a
              routerLink="/mainpage/"
              (click)="toggleMobile()"
              (click)="changeColor(0)"
              routerLinkActive="list-item-active"
              ><i class="fas fa-columns"></i>Dashboard</a
            >
          </span>
          <!-- <span class="links">
            <a
              (click)="toggleMobile()"
              routerLink="/mainpage/codreceipthistory"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD Remittances</a
            >
          </span> -->
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(1)"
              routerLink="/mainpage/customertransactions"
              routerLinkActive="list-item-active"
              ><i class="far fa-chart-bar"></i>Transaction History</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(2)"
              routerLink="/mainpage/walletsummary"
              routerLinkActive="list-item-active"
              ><i class="fas fa-wallet"></i>Wallet</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(3)"
              routerLink="/mainpage/paymentlog"
              routerLinkActive="list-item-active"
              ><i class="fa fa-credit-card"></i>Payment Logs</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(11)"
              routerLink="/mainpage/processterminalshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-credit-card"></i>Process Terminal Shipment</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(4)"
              routerLink="/mainpage/invoice"
              routerLinkActive="list-item-active"
              >
              <i class="fas fa-briefcase"></i>Invoice
              </a>
          </span>
          <span class="links"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(5)"
              routerLink="/mainpage/shipmenttracking"
              routerLinkActive="list-item-active"
              ><i class="fa fa-map-marker"></i> Track Shipment</a
            ></span
          >
          <span class="links"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(6)"
              routerLink="/mainpage/quickquote"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Quick Quotes</a
            ></span
          >
          <span class="links"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(7)"
              routerLink="/mainpage/cashondelivery"
              routerLinkActive="list-item-active"
              ><i class="fa fa-money"></i>COD</a
            ></span
          >
          <span class="links"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(8)"
              routerLink="/mainpage/allshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-truck"></i>Track Multiple Shipments </a
            ></span
          >
          
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 8, inactive: change != 8 }"
          >
            <span
              class="shipment-transaction"
              (click)="changeColor(8)"
              (click)="toggleMobile()"
              (click)="registerShipment()"
              ><i class="fas fa-ship"></i>Oversea Shipping</span
            >
          </span> -->
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 6, inactive: change != 6 }"
          >
            <a
              (click)="toggleMobile()"
              (click)="changeColor(7)"
              routerLink="/mainpage/shipmenttransaction"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Shipment Transaction</a
            ></span
          > -->
          <!-- <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/sla"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>SLA</a
            ></span
          > -->
          <!-- <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/createshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-plus"></i>Create Shipment</a
            ></span
          >
          <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/pickuprequest"
              routerLinkActive="list-item-active"
              ><i class="fas fa-people-carry"></i>Pickup request</a
            ></span
          > -->
        </div>
      </div>
    </div>
  </div>

  <div class="nav-content flex-10" [ngClass]="{ content: toggle }">
    <div class="nav-navbar">
      <div class="navbar-items">
        <i (click)="toggleSideBar()" class="fas fa-bars for-desktop"></i>
        <i (click)="toggleMobile()" class="fas fa-bars for-mobile"></i>
       
        <span class="date-time">{{ getTime() | date }}</span>
      </div>
      <div class="navbar-items user-channel">
        <div class="navbar-items_greetings">
          <div id="greet">
            
            <app-greeting></app-greeting>
          </div>
       
        <div class="flex px-3 pro ">
          <i class="fa fa-user-circle"></i>
        <span class="channel-code" [matMenuTriggerFor]="menu">{{
          userCode
        }}</span>
        <i
          class="fas fa-caret-down info-drop-down"
          [matMenuTriggerFor]="menu"
        ></i>
        <i style="cursor: pointer; padding: 0px 3px;" class="fa fa-sign-out"  (click)="confirmLogout()"></i>
        </div>       
      </div>

        <mat-menu #menu="matMenu">
          <button
            class="icon"
            mat-menu-item
            (click)="profile()"
            (click)="changeColor(9)"
          >
            <i class="fas fa-id-card drop-down"></i>Profile
          </button>
          <button
            class="icon"
            mat-menu-item
            (click)="changePassword()"
            (click)="changeColor(10)"
          >
            <i class="far fa-edit drop-down"></i>Change Password
          </button>
          <button
          class="icon logout-btn"
          mat-menu-item
          (click)="confirmLogout()"
        >
          <i class="fas fa-sign-out-alt drop-down"></i>Logout
        </button>
        </mat-menu>
      </div>
      <div>
      </div>
    </div>

    <div class="template">
      <div class="header-text">
        <span>Home</span> >
        {{ navPage }}
      </div>

      <router-outlet></router-outlet>
    </div>
  </div>
</div>

