import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiCall } from "../services/api-call";
import { MatPaginator } from "@angular/material/paginator";
import { DataAccess } from "../helpers/dataAccess";

@Component({
  selector: "app-payment-log",
  templateUrl: "./payment-log.component.html",
  styleUrls: ["./payment-log.component.scss"],
})
export class PaymentLogComponent implements OnInit {
  dataAccess = new DataAccess();
  dataSource!: MatTableDataSource<any>;
  paymentLog: any;
  customerRefrence: any;
  spinning: boolean = true;
  message!: string;
  gatewayMessage!: string;
  revalidate: boolean = false;
  // responsible for table column
  displayedColumns: string[] = [
    "reference",
    "amount",
    "status",
    "description",
    "date",
    "validation",
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private apiCall: ApiCall) {}
  apiError = false;
  public errorMessage!: string;
  closePopUp2() {
    this.apiError = false;
  }
  getPaymentLog() {
    this.apiCall.getPaymentLog().subscribe((data) => {
      this.spinning = true;
      if (data.Code == 200) {
        this.spinning = false;
        this.paymentLog = data.Object;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.paymentLog);
      this.dataSource.paginator = this.paginator;
    });
  }

  verifyPayment(i: string | number) {
    this.spinning = true;
    this.apiCall
      .getVerifyPayment(this.paymentLog[i].Reference)
      .subscribe((data) => {
        if (data.Code == 200) {
          this.spinning = false;
          this.revalidate = true;
          this.message = data.Object.Message;
          this.gatewayMessage = data.Object.GatewayResponse;
        } else {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }
      });
  }

  trackWaybill(i: any) {
    // console.log('clicked');
  }
  // closes pop-up
  closePopUp() {
    this.getPaymentLog();
    location.reload();
    this.revalidate = false;
  }
  ngOnInit(): void {
    this.getPaymentLog();
  }
}
