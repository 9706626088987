<div class="overlays" *ngIf="submit">
  <div class="password-message">
    <div class="message">{{message}}</div>
    <button (click)='reload()'>Ok</button>
  </div>
</div>

<div class="container-class">
  <div class="container-class-two">
    <!-- <app-greeting></app-greeting> -->
    <div class="process-terminal-shipment" *ngFor="let transaction of processTerminalShipmentData; let i = index">
      <div class="waybill-amount">
        <div class="terminal-waybill">Waybill: {{ transaction.Waybill }}</div>
        <div class="terminal-amount">
          Amount: {{ transaction.CurrencySymbol }}
          {{ transaction.Amount | number }}
        </div>
      </div>

      <div class="terminal-payment-button"><button (click)="processPayment(i)">pay</button></div>
    </div>
  </div>
</div>