<!-- <div class="overlay" *ngIf="spinning">
    <div class="center">
        <div class="spinner-grow spin" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div> -->
<div id="login-page-full">
  <div id="login-full-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div id="login-box">
            <div id="login-box-holder">
              <div class="row">
                <div class="col-xs-12">
                  <header id="login-header">
                    <div id="login-logo">
                      <img src="./assets/images/GIGLogin.png" alt="" />
                      <img src="./assets/images/GIGLoginLine.png" alt="" />
                    </div>
                  </header>
                  <div id="login-box-inner" class="ngdialog-theme-default">
                    <div class="login-options">
                      <!-- <input
                        (click)="toggleSignUpIndividual($event)"
                        type="radio"
                        id="individual"
                        name="signup"
                        checked="individual"
                        value="individual"
                      /> this is the initial code i tokk out $event -->
                      <input
                        (click)="toggleSignUpIndividual()"
                        type="radio"
                        id="individual"
                        name="signup"
                        checked="individual"
                        value="individual"
                      />
                      <label class="loginOptions" for="individual"
                        >Individual</label
                      >
                      <input
                        [(ngModel)]="testing"
                        (click)="toggleSignUpCompany()"
                        type="radio"
                        id="company"
                        name="signup"
                        value="company"
                      />
                      <label class="loginOptions" for="company">Company</label>
                    </div>

                    <!-- <form (submit)="IndividualSignup($event)" *ngIf="trial"> ***i took off $event -->
                    <form (submit)="IndividualSignup()" *ngIf="trial">
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          name="Username"
                          type="text"
                          placeholder="First Name"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          name="Username"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          name="Username"
                          type="text"
                          placeholder="Email address"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-key"></i
                        ></span>
                        <input
                          type="number"
                          name="PhoneNumber"
                          class="form-control"
                          placeholder="Phone Number"
                        />
                      </div>

                      <div class="row">
                        <div class="col-xs-12">
                          <a href="./app/app.component.html"
                            ><button
                              type="submit"
                              class="btn btn-danger col-xs-12"
                            >
                              Get Started
                            </button></a
                          >
                          <div
                            style="
                              margin-top: 60;
                              text-align: center;
                              font-weight: 700;
                              color: red;
                            "
                          >
                            <!-- {{ errorMessage }} -->
                          </div>
                        </div>
                      </div>
                    </form>
                    <!-- <form (submit)="CompanySignup($event)" *ngIf="test">  i took off the $event-->
                    <form (submit)="CompanySignup()" *ngIf="test">
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>
                        <input
                          class="form-control"
                          name="Companyname"
                          type="text"
                          placeholder="Company Name"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-key"></i
                        ></span>
                        <input
                          type="text"
                          name="Email"
                          class="form-control"
                          placeholder="Email"
                        />
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-key"></i
                        ></span>
                        <input
                          type="number"
                          name="PhoneNumber"
                          class="form-control"
                          placeholder="Phone Number"
                        />
                      </div>

                      <div class="row">
                        <div class="col-xs-12">
                          <a href="./app/app.component.html"
                            ><button
                              type="submit"
                              class="btn btn-danger col-xs-12"
                            >
                              Get Started
                            </button></a
                          >
                          <div
                            style="
                              margin-top: 60;
                              text-align: center;
                              font-weight: 700;
                              color: red;
                            "
                          >
                            <!-- {{ errorMessage }} -->
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="return-login" (click)="returnToLogin()">
                      Login
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<div id="login-box-footer">
    <div class="row">
        <div class="col-xs-12">
            Do not have an account?
            <a href="~/Register">
                Register now
            </a>
        </div>
    </div>
</div>-->
        </div>
      </div>
    </div>
  </div>
</div>
