
<div class="container-class">
  <div class="container-content">
    <div class="page-text">
      <h2>PickUp Request</h2>
      <div class="Pickup-request-table display-tbl">
        <div class="Pickup-request-table-container">
          <div class="Pickup-request-table-content table-content">
            <div class="table table-container">
              <table
                mat-table
                [dataSource]="dataSource"
                class="mat-elevation-z8"
              >
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <mat-header-cell id="table_header"  *matHeaderCellDef>SN</mat-header-cell>
                  <mat-cell id="table_cell" *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>
                <!-- items column -->
                <ng-container matColumnDef="waybill">
                  <mat-header-cell id="table_header"  *matHeaderCellDef>WAYBILL</mat-header-cell>
                  <mat-cell id="table_cell" *matCellDef="let element">
                    {{ element.Waybill }}
                  </mat-cell>
                </ng-container>

                <!-- Departure Column -->
                <ng-container matColumnDef="name">
                  <mat-header-cell id="table_header"  *matHeaderCellDef
                    >RECEIVER NAME</mat-header-cell
                  >
                  <mat-cell id="table_cell" *matCellDef="let element">
                    {{ element.ReceiverName }}
                  </mat-cell>
                </ng-container>

                <!-- Destination Column -->
                <ng-container matColumnDef="submitted">
                  <mat-header-cell id="table_header"  *matHeaderCellDef
                    >SUBMITTED AMOUNT</mat-header-cell
                  >
                  <mat-cell id="table_cell" *matCellDef="let element">
                    ₦ {{ element.GrandTotal | number }}
                  </mat-cell>
                </ng-container>
                <!-- partner column -->
                <ng-container matColumnDef="calculated">
                  <mat-header-cell id="table_header"  *matHeaderCellDef
                    >CALCULATED AMOUNT</mat-header-cell
                  >
                  <mat-cell id="table_cell" *matCellDef="let element">
                    ₦ {{ element.CalculatedTotal | number }}
                  </mat-cell>
                </ng-container>
                <!-- date column -->
                <ng-container matColumnDef="status">
                  <mat-header-cell id="table_header"  *matHeaderCellDef>STATUS</mat-header-cell>
                  <mat-cell id="table_cell" *matCellDef="let element">
                    {{ element.shipmentstatus }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                  <mat-header-cell id="table_header"  *matHeaderCellDef
                    >DATE CREATED</mat-header-cell
                  >
                  <mat-cell id="table_cell" *matCellDef="let element">
                    {{ element.DateCreated | date: "dd/MM/yyyy hh:mm.a" }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="button">
                  <mat-header-cell id="table_header"  *matHeaderCellDef></mat-header-cell>
                  <mat-cell id="table_cell" *matCellDef="let element; let i = index">
                    <i
                      class="fas fa-search-plus"
                      (click)="pickupRequestDetail(i)"
                    ></i>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <!-- <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row> -->
                <mat-row
  *matRowDef="let row; let i = index; columns: displayedColumns;"
  [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
></mat-row>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
