import { ChangeDetectorRef, Component } from "@angular/core";

@Component({
  selector: "app-greeting",
  templateUrl: "./greeting.component.html",
  styleUrls: ["./greeting.component.css"],
})
export class GreetingComponent {
  greetingMessage: string = "";
  FirstName!: string;
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.setGreetingMessage();
  }

  setGreetingMessage(): void {
    const currentHour = new Date().getHours();
    // .replace(/^"|"$/g, '') REMOVES THE UNECCESSARY QUOTE
    this.FirstName = (localStorage.getItem("FirstName") || "").replace(
      /(^"|"$)/g,
      ""
    );
    this.cdr.markForCheck();

    if (currentHour < 12) {
      this.greetingMessage = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 18) {
      this.greetingMessage = "Good Afternoon";
    } else {
      this.greetingMessage = "Good Evening";
    }
  }
}
