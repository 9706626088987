import { Component, OnInit } from "@angular/core";
import { Login } from "./../models/login";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "../services/login.service";
import { AuthHelpers } from "../helpers/auth";
import { DataAccess } from "../helpers/dataAccess";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public LoginModel: Login = new Login();
  public authHelpers = new AuthHelpers();
  public dataAccess = new DataAccess();
  public errorMessage!: string;
  public returnUrl!: string;
  passwordVisible = false;

  spinning: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private login: LoginService
  ) {}
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }


  public getLogin() {
    localStorage.clear();
    this.spinning = true;
    this.login.getLogin(this.LoginModel).subscribe(
      (data) => {
        this.spinning = true;
        if (data.Code == 200) {
          this.spinning = false;
          this.dataAccess.setLocalStorageValue("Selection", 0);
          const { access_token } = data.Object;

          this.dataAccess.setLocalStorageValue(
            "CustomerType",
            data.Object.UserChannelType
          );
          this.authHelpers.setAccessToken(
            "phoneNumber",
            data.Object.PhoneNumber
          );
          this.authHelpers.setAccessToken("email", data.Object.Email);
          this.authHelpers.setAccessToken("accessToken", access_token);
          this.authHelpers.setAccessToken("userId", data.Object.UserId);
          this.authHelpers.setAccessToken("FirstName", data.Object.FirstName);
          this.authHelpers.setAccessToken("userCode", data.Object.UserName);
          this.authHelpers.setAccessToken(
            "isInternational",
            data.Object.IsInternational
          );
          if (access_token) {
            if (this.returnUrl !== "/") {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(["mainpage"]);
            }
          }
        } else {
          this.spinning = false;
          this.errorMessage = data.ShortDescription;
        }
      },
      (error) => {
        if (error.status == 0) {
          this.spinning = false;
          this.errorMessage = "No internet connection";
        }
      }
    );
  }
  SubmitLogin(event: Event) {
    this.getLogin();
    event.preventDefault();
  }
  signUp() {
    this.router.navigate(["signup"]);
  }
  forgotPassword() {
    this.router.navigate(["forgotpassword"]);
  }
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }
}
