import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiCall } from "../services/api-call";

@Component({
  selector: "app-trackbtn",
  templateUrl: "./trackbtn.component.html",
  styleUrls: ["./trackbtn.component.css"],
})
export class TrackbtnComponent {
   waybill!: number; 
  public errorMessage!: string;
  apiError: boolean = false;
  spinning: boolean = false;

  constructor(private apiCall: ApiCall, private router: Router, private route: ActivatedRoute,) {
    this.route.params.subscribe(params=>this.waybill = params["waybill"])
  }
  ngOnInit(): void {
    // this.getToTrackWaybill();
  }

  // getToTrackWaybill(): void {
    
  //   this.spinning = true;
  //   this.apiCall.getTrackShipmentWaybill(this.waybill).subscribe(
  //     {
  //       next: (data) => {
  //         if (data.Code === 200){
  //               console.log('Waybill:', this.waybill); 
  //           this.router.navigate(['/mainpage/shipmenttracking', this.waybill]);
  //         }else{
  //           this.apiError = true;
  //           this.errorMessage = data.ShortDescription;
  //         }
  //         this.spinning = false;
  //       },
  //       error: (error) => {
  //         this.spinning = false;
  //         this.apiError = true;
  //         this.errorMessage = "error occuredd";
  //         console.error('error tracking shipment with waybill', error)
  //       }
  //     }
  //   );
  // }
}
