import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatPaginatorModule, MatPaginator } from "@angular/material/paginator";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CodremittancesComponent } from "./codremittances/codremittances.component";
import { TransactionHistoryComponent } from "./transaction-history/transaction-history.component";
import { WalletComponent } from "./wallet/wallet.component";
import { PaymentLogComponent } from "./payment-log/payment-log.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { TrackingComponent } from "./tracking/tracking.component";
import { QuickQuoteComponent } from "./quick-quote/quick-quote.component";
import { CodComponent } from "./cod/cod.component";
import { SlaComponent } from "./sla/sla.component";
import { MatMenuModule } from "@angular/material/menu";
import { CreateshipmentComponent } from "./createshipment/createshipment.component";
import { PickUpReequestComponent } from "./pick-up-reequest/pick-up-reequest.component";
import { NavSideBarComponent } from "./nav-side-bar/nav-side-bar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProfileComponent } from "./profile/profile.component";
import { PasswordChangeComponent } from "./password-change/password-change.component";
import { MatTableModule } from "@angular/material/table";
import { Config } from "./models/utility";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { Angular4PaystackModule } from "angular4-paystack";
import { ViewInvoiceComponent } from "./view-invoice/view-invoice.component";
import { NgxPrintModule } from "ngx-print";
import { PickUpRequestDetailComponent } from "./pick-up-request-detail/pick-up-request-detail.component";
import { SignupComponent } from "./signup/signup.component";
import { MatTableExporterModule } from "mat-table-exporter";
import { ShipmentTransactionComponent } from "./shipment-transaction/shipment-transaction.component";
import { PublictrackComponent } from "./publictrack/publictrack.component";
import { PaymentPageComponent } from "./payment-page/payment-page.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ProcessTerminalShipmentComponent } from "./process-terminal-shipment/process-terminal-shipment.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
// import { Select2Module } from "ng-select2-component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgSelectModule } from "@ng-select/ng-select";
import { CustomTextComponent } from './custom-text/custom-text.component';
import { TrackbtnComponent } from './trackbtn/trackbtn.component';
import { GreetingComponent } from './greeting/greeting.component';
import { ModalComponent } from './modal/modal.component';
import { MatDialogModule } from "@angular/material/dialog";
import { TrackComponent } from './track/track.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from "./core/interceptors/loading.interceptor";
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrModule } from "ngx-toastr";
import { ProofofdeliveryComponent } from './proofofdelivery/proofofdelivery.component';
import { LogoutConfirmationComponent } from './logout-confirmation/logout-confirmation.component';
// export function RestangularConfigFactory(RestangularProvider) {
//   RestangularProvider.setBaseUrl(Config.baseUrl);
// }

// export function RestangularConfigFactory(RestangularProvider: any) {
//   RestangularProvider.setBaseUrl(Config.baseUrl);
// }
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CodremittancesComponent,
    TransactionHistoryComponent,
    WalletComponent,
    PaymentLogComponent,
    InvoiceComponent,
    TrackingComponent,
    QuickQuoteComponent,
    CodComponent,
    SlaComponent,
    CreateshipmentComponent,
    PickUpReequestComponent,
    NavSideBarComponent,
    LoginComponent,
    ProfileComponent,
    PasswordChangeComponent,
    ForgotPasswordComponent,
    ViewInvoiceComponent,
    PickUpRequestDetailComponent,
    SignupComponent,
    ShipmentTransactionComponent,
    PublictrackComponent,
    PaymentPageComponent,
    ProcessTerminalShipmentComponent,
    CustomTextComponent,
    TrackbtnComponent,
    GreetingComponent,
    ModalComponent,
    TrackComponent,
    ShipmentComponent,
    ProofofdeliveryComponent,
    LogoutConfirmationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    // GooglePlaceModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    
    MatAutocompleteModule,
    // Select2Module,
    NgxSpinnerModule.forRoot({type: 'ball-scale-multiple'}),
    HttpClientModule,
    NgSelectModule,
    MatPaginatorModule,
    NgxPrintModule,
    ClipboardModule,
    MatTooltipModule,
    MatTableExporterModule,
    Angular4PaystackModule.forRoot(
      "pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b"
    ),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  }
],
  bootstrap: [AppComponent],
})
export class AppModule {}
