import { Component, OnInit } from "@angular/core";
import { ApiCall } from "../services/api-call";
import { DataAccess } from "../helpers/dataAccess";
import { ProfileService } from "../services/profile.service";
@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  dataAccess = new DataAccess();
  userCode = this.dataAccess.getLocalStorageValue("userId");
  spinning: boolean = true;
  profileObj: any;
  customerName!: string;
  customerAddress!: string;
  customerEmail!: string;
  customerCode!: string;
  customerNumber: any;
  constructor(private apiCall: ApiCall, private profileService: ProfileService) {}

  ngOnInit(): void {
    this.apiCall.getUserProfile(this.userCode).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.profileObj = data.Object;
       
              this.profileService.updateCustomerName(this.profileObj.CustomerName);
      }

    });
  }
}
